/* eslint-disable */
import React, { useEffect, useState } from 'react';
import './ScreenD.css';
import MyMap from "./components/MyMap";
import { loadMapApi } from "./components/utils/GoogleMapsUtils";
import useWatchLocation from "./components/hooks/useWatchLocation";
import Location from "./components/Location";
import Orientation from "./components/Orientation";
import Health from "./components/Health";
import WebSocketComponent from "./WebSocketComponent";


import Target from "./components/objects/targets/Target";
import Weapon from './components/objects/weapons/Weapon';
import IndirectFireWeapon from './components/objects/weapons/IndirectFireWeapon';
import DirectFireWeapon from "./components/objects/weapons/DirectFireWeapon";
import PlacedWeapon from "./components/objects/weapons/PlacedWeapon";
import AmmoDropPoint from './components/objects/weapons/AmmoDropPoint';
import PlacedAmmo from './components/objects/weapons/PlacedAmmo';

import BackgroundAudio from './BackgroundAudio';

import { v4 as uuidv4, parse as uuidParse } from 'uuid';
import { convertCompilerOptionsFromJson } from 'typescript';
import { Link } from 'react-router-dom';


import Modal from 'react-modal';
import './login.css';

import { MessageFields, MessageTypes, LocationChangedFields, KillOtherPlayerFields, NearMissFields, GameSettingsFields, TeamWinFields, DropPointFields } from './components/utils/WSMessageFormatUtils';

Modal.setAppElement("#root");

const myID = uuidv4();

const webSocket = new WebSocket(process.env.REACT_APP_WS_URL);


const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 5, // 5 sec (1000 ms * 5 sec = 5 000ms)
  maximumAge: 0, // give most up to date location
};


function ScreenD () {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [userName, setUserName] = useState();
  const [teams, setTeams] = useState([]);
  const [team, setTeam] = useState();
  
  const [playerMessage, setPlayerMessage] = useState();
  const [calculateDropPointsId, setCalculateDropPointsId] = useState(-1);
  const [doIHaveFlag, setDoIHaveFlag] = useState(false);
  const [doIHaveEnemyFlag, setDoIHaveEnemyFlag] = useState(false);

  const [isTest, setTest] = useState(false);
  const [isSettings, setSettings] = useState(false);

  const [isAdmin, setAdmin] = useState(true);

  const [isRunning, setIsRunning] = useState(false);

  const [scriptLoaded, setScriptLoaded] = useState(false);

  const [map, setMap] = useState()

  const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
  const [orientation, setOrientation] = useState(0);

  const [meMarker, setMeMarker] = useState();
  const [directFireMarker, setDirectFireMarker] = useState();
  const [indirectFireMarker, setIndirectFireMarker] = useState();
  const [mapCenterSet, setMapCenterSet] = useState(false);
  const [hideNext, setHideNext] = useState(true);

  const [targets, setTargets] = useState([]);
  let iedsOnHand = [];
  let placedIeds = [];
  let deployedIeds = [];

  const [placedWeapons, setPlacedWeapons] = useState([]);
  const noneWeapon = new Weapon("None");
  const [weaponsOnHand, setWeaponsOnHand] = useState([noneWeapon,]);
  const [equippedWeapon, setEquippedWeapon] = useState(noneWeapon);

  const [placedAmmoDropPoints, setPlacedAmmoDropPoints] = useState([]);
  const [placedAmmo, setPlacedAmmo] = useState([]);

  const [showMenu, setShowMenu] = useState(false);
  const [showEquipment, setShowEquipment] = useState(false);
  const [showWeapons, setShowWeapons] = useState(false);

  const [gameStarted, setGameStarted] = useState(false);
  const [calculateDropPoints, setCalculateDropPoints] = useState(false);

  const [weaponDropPoints, setWeaponDropPoints] = useState([]);
  const [supplyDropPoints, setSupplyDropPoints] = useState([]);

  const [timer, setTimer] = useState(0);

  const [pistol, makePistol] = useState(new DirectFireWeapon("Pistol", "gunShotAudio", 9, 10, "9mm", 1, "#00FF00", 8));
  const [m4, makeM4] = useState(new DirectFireWeapon("M4", "m4ShotAudio", 4.5, 10, "5.56", 1, "#0000FF", 10));
  const [grenadeLauncher, makeGrenadeLauncher] = useState(new IndirectFireWeapon("Grenade_Launcher", "grenadeLauncherShotAudio", 3, 8, "40mm_Grenade", 1, "#FF0000", 15, 5));
  const mortar = new IndirectFireWeapon("Mortar", "mortarShotAudio", 4.5, 4, "80mm_Mortar", 1, "#FF00FF", 25, 10);

  const sameTeamPlayerAliveIcon = "https://maps.google.com/mapfiles/kml/pal4/icon57.png";
  const sameTeamPlayerDeadIcon = "https://maps.google.com/mapfiles/kml/pal4/icon56.png";
  const sameTeamPlayerEnemyFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon59.png";
  const otherTeamPlayerAliveIcon = "https://maps.google.com/mapfiles/kml/pal4/icon49.png";
  const otherTeamPlayerDeadIcon = "https://maps.google.com/mapfiles/kml/pal4/icon48.png";
  const otherTeamPlayerYourFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon47.png";
  const sameTeamBaseIcon = "https://maps.google.com/mapfiles/kml/pal4/icon61.png";
  const otherTeamBaseIcon = "https://maps.google.com/mapfiles/kml/pal4/icon53.png";
  const sameTeamFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon60.png";
  const otherTeamFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon52.png";
  const meIcon = "https://maps.google.com/mapfiles/kml/pal3/icon28.png";
  const greyBoxIcon = "https://maps.google.com/mapfiles/kml/pal4/icon11.png";
  const orangeBoxIcon = "./images/ammo_2.jpg";
  const purpleBoxIcon = "https://maps.google.com/mapfiles/kml/pal4/icon20.png";

  const HealthValues = Object.freeze({
    Alive: 9,
    Injured8: 8,
    Injured7: 7,
    Injured6: 6,
    Injured5: 5,
    Injured4: 4,
    Injured3: 3,
    Injured2: 2,
    Injured1: 1,
    Dead: 0
  });

  const [orientationRange, setOrientationRange] = useState(3);
  const [pickupRange, setPickupRange] = useState(0.00007);

  const [uniqueId, setUniqueId] = useState(1);

  const [otherPlayers, setOtherPlayers] = useState(new Map());

  const [myNumericalHealth, setMyNumericalHealth] = useState(HealthValues.Alive);

  const [queuedMarkers, setQueuedMarkers] = useState([]);
  const [flagMarkers, setFlagMarkers] = useState(new Map());
  const [baseMarkers, setBaseMarkers] = useState(new Map());
  const [weaponDropPointMarkers, setWeaponDropPointMarkers] = useState(new Map());
  const [supplyDropPointMarkers, setSupplyDropPointMarkers] = useState(new Map());

  const [closestObject, setClosestObject] = useState(null);
  const [disableStart, setDisableStart] = useState(true);
  
  
  webSocket.onmessage = (event) => {
    const messageAttributes = event.data.split(" ");
    console.log("websocket message")
    //console.log("webSocket " + event.data);
    /*messageAttributes.forEach((attrib) => {
          console.log(attrib);
        });*/


    if (messageAttributes[MessageFields.messageId] === String(myID)) {
      return;
    }
    processMessage(messageAttributes);

  };

  function processMessage(messageAttributes) {
    switch (messageAttributes[MessageFields.messageType]) {
      case MessageTypes.locationChangedMessageType:
        processLocationChangedMessage(messageAttributes);
        break;
      case MessageTypes.clientDisconnectedMessageType:
        processClientDisconnectedMessage(messageAttributes);
        break;
      case MessageTypes.killOtherPlayerMessageType:
        processKillOtherPlayerMessage(messageAttributes);
        break;
      case MessageTypes.updateTeamsMessageType:
        processUpdateTeamsMessage(messageAttributes);
        break;
      case MessageTypes.updateTeamFlagLocationMessageType:
        processUpdateTeamFlagLocationMessage(messageAttributes);
        break;
      case MessageTypes.updateTeamFlagCarryMessageType:
        processUpdateTeamFlagCarryMessage(messageAttributes);
        break;
      case MessageTypes.nearMissMessageType:
        processNearMissMessage(messageAttributes);
        break;
      case MessageTypes.gameSettingsMessageType:
        processGameSettingsMessage(messageAttributes);
        break;
      case MessageTypes.updateTeamBaseLocationMessageType:
        processUpdateTeamBaseLocationMessage(messageAttributes);
        break;
      case MessageTypes.teamWinMessageType:
        processTeamWinMessage(messageAttributes);
        break;
      case MessageTypes.dropPointMessageType:
        processDropPointMessage(messageAttributes);
        break;
      case MessageTypes.startGameMessageType:
        processStartGameMessage(messageAttributes);
        break;
	  case MessageTypes.resetServerMessageType:
	    processResetServerMessage(messageAttributes);
		break;

      default:
        console.log("socket " + messageAttributes[MessageFields.messageType] + " " + messageAttributes[MessageFields.messageId]);
    }
  }

  function processResetServerMessage(messageAttributes) {
	console.log("reset server");
	window.location.assign("../"); // Update path
	//window.location.reload();
  }

  function processStartGameMessage(messageAttributes) {
    console.log("game started");
    setGameStarted(true);
	setPlayerMessage("Grab weapons and ammo! Bring the enemy's flag back to your base to win!");
	document.getElementById('start game').disabled = true;
	document.getElementById("start game").style.backgroundColor = "green";

  }

  function processDropPointMessage(messageAttributes) {
    console.log(grenadeLauncher);
    console.log(mortar);
    for (let i = 0; i < messageAttributes.length; i++) {
      console.log(messageAttributes[i]);
    }
	setCalculateDropPointsId(messageAttributes[DropPointFields.calcId]);


    if (!calculateDropPoints && myID === messageAttributes[DropPointFields.calcId]) {
      setCalculateDropPoints(true);
    }

    var tempWeaponDropPoints = JSON.parse(messageAttributes[DropPointFields.weaponPoints]);

    tempWeaponDropPoints.forEach((tempWeaponDropPoint) => {
      if (tempWeaponDropPoint.weapon) {
        if (tempWeaponDropPoint.weapon.name === "Grenade_Launcher") {
          tempWeaponDropPoint.weapon = grenadeLauncher;
        } else if (tempWeaponDropPoint.weapon.name === "Mortar") {
          tempWeaponDropPoint.weapon = mortar;
        }
      }
      if (tempWeaponDropPoint.active && tempWeaponDropPoint.location && tempWeaponDropPoint.weapon) {
        if (weaponDropPointMarkers.has(tempWeaponDropPoint.id)) {
          //can these things move? I don't think so. Not yet at least.
        } else {
          var weaponMarker = new google.maps.Marker({
            position: tempWeaponDropPoint.location,
            map,
            title: tempWeaponDropPoint.weapon.name,
            icon: { url: purpleBoxIcon, anchor: new google.maps.Point(16, 16) }
          });

          setWeaponDropPointMarkers(prev => new Map([...prev, [tempWeaponDropPoint.id, weaponMarker]]));

          if (!map) {
            queuedMarkers.push(weaponMarker);
          }
        }
      } else {
        let marker = weaponDropPointMarkers.get(tempWeaponDropPoint.id);
        marker && marker.setMap(null);
      }
    });

    weaponDropPoints.length = 0;
    setWeaponDropPoints(tempWeaponDropPoints);

    var tempSupplyDropPoints = JSON.parse(messageAttributes[DropPointFields.supplyPoints]);
    supplyDropPoints.length = 0;
    setSupplyDropPoints(tempSupplyDropPoints);

    tempSupplyDropPoints.forEach((tempSupplyDropPoint) => {
      if (tempSupplyDropPoint.active && tempSupplyDropPoint.location && tempSupplyDropPoint.supply) {
        if (supplyDropPointMarkers.has(tempSupplyDropPoint.id)) {
          //can these things move? I don't think so. Not yet at least.
          let marker = supplyDropPointMarkers.get(tempSupplyDropPoint.id);
          marker && marker.setMap(map) && marker.setTitle(tempSupplyDropPoint.supply.name);

        } else {
          var supplyMarker = new google.maps.Marker({
            position: tempSupplyDropPoint.location,
            map,
            title: tempSupplyDropPoint.supply.name,
            icon: { url: orangeBoxIcon, anchor: new google.maps.Point(16, 16) }
          });

          setSupplyDropPointMarkers(prev => new Map([...prev, [tempSupplyDropPoint.id, supplyMarker]]));

          if (!map) {
            queuedMarkers.push(supplyMarker);
          }
        }
      } else {
        let marker = supplyDropPointMarkers.get(tempSupplyDropPoint.id);
        marker && marker.setMap(null);
      }
    });
  }

  function processTeamWinMessage(messageAttributes) {
    if (team && team.id === messageAttributes[TeamWinFields.teamId]) {
      var winAudio = document.getElementById('winAudio');
      winAudio.pause();
      winAudio.currentTime = 0;
      winAudio.play();
    } else {
      var loseAudio = document.getElementById('loseAudio');
      loseAudio.pause();
      loseAudio.currentTime = 0;
      loseAudio.play();
    }
  }

  function processUpdateTeamBaseLocationMessage(messageAttributes) {
    if (!team)
      return;
    console.log("process base location update message " + messageAttributes[2] + " " + teams.length);
    teams.forEach((tempTeam) => {
      console.log("teams " + tempTeam.id);
      if (tempTeam.id == messageAttributes[2]) {
        console.log("making marker");
        tempTeam.baseLocation = { lat: Number(messageAttributes[3]), lng: Number(messageAttributes[4]) };

        var baseMarker = new google.maps.Marker({
          position: tempTeam.baseLocation,
          map,
          title: tempTeam.name + " Base",
          icon: { url: (tempTeam.id === team.id) ? sameTeamBaseIcon : otherTeamBaseIcon, anchor: new google.maps.Point(16, 16) }
        });
        //tempTeam.flag.marker = flagMarker;
        console.log("stats " + messageAttributes[3] + " " + messageAttributes[4]);
        //console.log(tempTeam.flag);
        console.log(tempTeam);

        setBaseMarkers(map => new Map(map.set(tempTeam.id, baseMarker)));

        if (!map) {
          queuedMarkers.push(baseMarker);
        }
      }
    });
  }

  function processGameSettingsMessage(messageAttributes) {
    console.log("process game settings message " + messageAttributes[GameSettingsFields.orientationRange] + " " + messageAttributes[3] + " " + messageAttributes[4] + " " + messageAttributes[5] + " " + messageAttributes[6]);

    setOrientationRange(Number(messageAttributes[GameSettingsFields.orientationRange]));
    setPickupRange(Number(messageAttributes[GameSettingsFields.pickupRange]));
    m4.range = messageAttributes[GameSettingsFields.m4Range];
    pistol.range = messageAttributes[GameSettingsFields.pistolRange];
    grenadeLauncher.range = messageAttributes[GameSettingsFields.grenadeLauncherRange];

  }

  function processNearMissMessage(messageAttributes) {
    console.log("process near miss message " + messageAttributes[2] + " " + messageAttributes[3]);
    if (messageAttributes[2] === myID) {
      switch (messageAttributes[3]) {
        case NearMissFields.directFire:
          var directFireMissAudio = document.getElementById('directFireMissAudio');
          directFireMissAudio.pause();
          directFireMissAudio.currentTime = 0;
          directFireMissAudio.play();
          break;
        case NearMissFields.indirectFire:
          var indirectFireMissAudio = document.getElementById('indirectFireMissAudio');
          indirectFireMissAudio.pause();
          indirectFireMissAudio.currentTime = 0;
          indirectFireMissAudio.play();
          break;
      }
    }
  }

  function processUpdateTeamFlagCarryMessage(messageAttributes) {
    console.log("process flag carry update message " + messageAttributes[2] + " " + teams.length);
    teams.forEach((tempTeam) => {
      console.log("teams " + tempTeam.id);
      if (tempTeam.id == messageAttributes[2]) {
        console.log("removing marker");
        if (flagMarkers.has(tempTeam.id)) {
          console.log("removing flag marker " + tempTeam.id);
          let marker = flagMarkers.get(tempTeam.id);
          marker.setMap(null);
        }
        tempTeam.flag.badguyCarrying = messageAttributes[3];
      }

    });
  }

  function processUpdateTeamFlagLocationMessage(messageAttributes) {
    if (!team)
      return;
    console.log("process flag location update message " + messageAttributes[2] + " " + teams.length);
    teams.forEach((tempTeam) => {
      console.log("teams " + tempTeam.id);
      if (tempTeam.id == messageAttributes[2]) {
        console.log("making marker");
        tempTeam.flag.location = { lat: Number(messageAttributes[3]), lng: Number(messageAttributes[4]) };

        var flagMarker = new google.maps.Marker({
          position: tempTeam.flag.location,
          map,
          title: tempTeam.name + " Flag",
          icon: { url: (tempTeam.id === team.id) ? sameTeamFlagIcon : otherTeamFlagIcon, anchor: new google.maps.Point(16, 16) }
        });
        tempTeam.flag.marker = flagMarker;
        console.log("stats " + messageAttributes[3] + " " + messageAttributes[4]);
        console.log(tempTeam.flag);
        console.log(tempTeam);

        setFlagMarkers(map => new Map(map.set(tempTeam.id, flagMarker)));

        if (!map) {
          queuedMarkers.push(flagMarker);
        }
      }
    });
  }

  function processUpdateTeamsMessage(messageAttributes) {
    console.log("process team update message " + team + " " + map);
	setDoIHaveFlag(false);

    if (document.getElementById('placeTeamFlag')) {
      document.getElementById('placeTeamFlag').disabled = true;
      document.getElementById('placeTeamFlag').style.visibility = 'hidden';
    }
    var tempTeams = JSON.parse(messageAttributes[2]);
    teams.length = 0;
    tempTeams.forEach((tempTeam) => {
      // teams.push(tempTeam);
      console.log("teams " + tempTeam.name + " " + tempTeam.players.length);
      tempTeam.players.forEach((player) => {
        console.log(player.id + " " + player.userName);
      });

      if (team) {
        if (tempTeam.flag.location) {
          console.log("creating marker " + tempTeam.flag.location);
          if (flagMarkers.has(tempTeam.id)) {
            flagMarkers.get(tempTeam.id).setPosition(tempTeam.flag.location);
          } else {
            var flagMarker = new google.maps.Marker({
              position: tempTeam.flag.location,
              map,
              title: tempTeam.name + " Flag",
              icon: { url: (tempTeam.id === team.id) ? sameTeamFlagIcon : otherTeamFlagIcon, anchor: new google.maps.Point(16, 16) }
            });
            tempTeam.flag.marker = flagMarker;
            setFlagMarkers(map => new Map(map.set(tempTeam.id, flagMarker)));
          }

          if (!map) {
            queuedMarkers.push(flagMarker);
          }
        } else {
          console.log("flag no location ");
          console.log(tempTeam.flag);
          if (flagMarkers.has(tempTeam.id)) {
            console.log("removing flag marker " + tempTeam.id);
            let marker = flagMarkers.get(tempTeam.id);
            marker.setMap(null);
          }
        }

        if (tempTeam.baseLocation) {
          if (baseMarkers.has(tempTeam.id)) {
            //shouldn't be able to move one placed, but we'll make sure it's in the right place
            baseMarkers.get(tempTeam.id).setPosition(tempTeam.baseLocation);
          } else {
            var baseMarker = new google.maps.Marker({
              position: tempTeam.baseLocation,
              map,
              title: tempTeam.name + " Base",
              icon: { url: (tempTeam.id === team.id) ? sameTeamBaseIcon : otherTeamBaseIcon, anchor: new google.maps.Point(16, 16) }
            });
            //tempTeam.flag.marker = flagMarker;
            setBaseMarkers(map => new Map(map.set(tempTeam.id, baseMarker)));
          }

          if (!map) {
            queuedMarkers.push(baseMarker);
          }
        }
      }

      if (team && (tempTeam.id == team.id)) {

        if (tempTeam.flag.playerIdHolding === myID) {
          console.log("I HAVE THE FLAG");
		  setPlayerMessage("Place the flag for your team, under tools");
          setDoIHaveFlag(true);
		  
          if (document.getElementById('placeTeamFlag')) {
            document.getElementById('placeTeamFlag').disabled = false;
            document.getElementById('placeTeamFlag').style.visibility = 'visible';
          }
        }

        setTeam(tempTeam);
      }
      setTeams(tempTeams);

    });
  }

  function processKillOtherPlayerMessage(messageAttributes) {
    console.log("someone's shooting me " + messageAttributes[KillOtherPlayerFields.killOtherPlayerId] + " " + (messageAttributes[KillOtherPlayerFields.killOtherPlayerId] === String(myID)) + " " + messageAttributes[KillOtherPlayerFields.damage]);
    if (messageAttributes[KillOtherPlayerFields.killOtherPlayerId] === String(myID)) {
      setMyNumericalHealth(myNumericalHealth - messageAttributes[KillOtherPlayerFields.damage]);
      /*switch (myNumericalHealth) {
        case HealthValues.Alive:
          setMyNumericalHealth(HealthValues.Injured1);
          break;
        case HealthValues.Injured1:
          setMyNumericalHealth(HealthValues.Injured2);
          break;
        case HealthValues.Injured2:
          setMyNumericalHealth(HealthValues.Dead);
          break;
        case HealthValues.Dead:
          // do nothing, already dead.
          break;
      }*/

      console.log("I got injured and my health is " + myNumericalHealth);
    }
  }

  function processDead() {
    if (myNumericalHealth === HealthValues.Dead) {
      setPlayerMessage("You died!");
	  console.log("killed other player " + myNumericalHealth);
      var deadAudio = document.getElementById('deadAudio');
      deadAudio.pause();
      deadAudio.currentTime = 0;
      deadAudio.play();
	  console.log("process dead")

      teams.forEach((tempTeam) => {
        if (tempTeam.flag.badguyCarrying && tempTeam.flag.badguyCarrying === myID) {
          console.log("DROPPING FLAG");
		  setPlayerMessage("You dropped the flag");
          //webSocket.send(MessageTypes.updateTeamFlagLocationMessageType + " " + "0" + " " + tempTeam.id + " " + location.latitudelat + " " + location.longitude);
          setDoIHaveEnemyFlag(false);
          meMarker.setIcon(meIcon);

		   
		  tempTeam.flag.badguyCarrying = null;
          tempTeam.flag.location = { lat: location.latitude, lng: location.longitude };

          console.log(teams);
          //(webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.updateTeamsMessageType + " " + "-2" + " " + JSON.stringify(teams));
          (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.updateTeamFlagLocationMessageType + " " + "0" + " " + tempTeam.id + " " + location.latitude + " " + location.longitude);
        }
      });
    }
  }

  function processClientDisconnectedMessage(messageAttributes) {
    if (otherPlayers.has(messageAttributes[MessageFields.messageId])) {
      let target = otherPlayers.get(messageAttributes[MessageFields.messageId]);
      target.marker.setMap(null);
      otherPlayers.delete(messageAttributes[MessageFields.messageId]);
    }
  }
  
  function startGame() {
        console.log("starting game");
		console.log(calculateDropPointsId);
		console.log(JSON.stringify(weaponDropPoints));
		console.log(JSON.stringify(supplyDropPoints));
		
        (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + calculateDropPointsId + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
		(webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.startGameMessageType);
		document.getElementById('start game').disabled = true;
		document.getElementById("start game").style.backgroundColor = "green";
	}

  function resetServer() {
        var result = confirm("Are you sure you want to start a new game for everyone?");
        if (result)
            (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.resetServerMessageType);
    }

  function processLocationChangedMessage(messageAttributes) {
    if (!scriptLoaded)
      return;
    var icon;
    if (sameTeam(messageAttributes[MessageFields.messageId])) {
      if (messageAttributes[LocationChangedFields.health] != HealthValues.Dead) {
        icon = sameTeamPlayerAliveIcon;
      } else {
        icon = sameTeamPlayerDeadIcon;
      }
    } else {
      if (messageAttributes[LocationChangedFields.health] != HealthValues.Dead) {
        var carryingFlag = false;
        teams.forEach((tempTeam) => {
          if (tempTeam.flag.badguyCarrying && tempTeam.flag.badguyCarrying === messageAttributes[MessageFields.messageId])
            carryingFlag = true;
        });
        icon = carryingFlag ? otherTeamPlayerYourFlagIcon : otherTeamPlayerAliveIcon;
      } else {
        icon = otherTeamPlayerDeadIcon;
      }
    }

    if (otherPlayers.has(messageAttributes[MessageFields.messageId])) {
      let target = otherPlayers.get(messageAttributes[MessageFields.messageId]);
      target.location = { latitude: Number(messageAttributes[LocationChangedFields.latitude]), longitude: Number(messageAttributes[LocationChangedFields.longitude]) }
      target.marker.setPosition(new google.maps.LatLng(messageAttributes[LocationChangedFields.latitude], messageAttributes[LocationChangedFields.longitude]));
      target.marker.setIcon({ url: icon, anchor: new google.maps.Point(16, 16) });
    } else {
      console.log("new other player " + messageAttributes[MessageFields.messageId]);
      var targetMarker = new google.maps.Marker({
        position: new google.maps.LatLng(messageAttributes[LocationChangedFields.latitude], messageAttributes[LocationChangedFields.longitude]),
        map,
        title: messageAttributes[MessageFields.messageId],
        icon: { url: icon, anchor: new google.maps.Point(16, 16) }
      });

      if (!map) {
        queuedMarkers.push(targetMarker);
      }

      //TODO: infowindow1 - map may be null here
      google.maps.event.addListener(targetMarker, 'click', function () {
        var content = '<div>ID: ' + target.id + '<br>';
        content += '</div>';
        const infoWindow1 = new google.maps.InfoWindow({
          content: content
        });
        infoWindow1.open(map, targetMarker);
      });


      let target = new Target(messageAttributes[MessageFields.messageId], { latitude: Number(messageAttributes[LocationChangedFields.latitude]), longitude: Number(messageAttributes[LocationChangedFields.longitude]) }, targetMarker, true);

      setOtherPlayers(map => new Map(map.set(messageAttributes[MessageFields.messageId], target)));
    }

  }

  useWatchLocation(geolocationOptions, setLocation);

  useEffect(() => {
    const googleMapScript = loadMapApi();
    googleMapScript.addEventListener('load', function () {
      setScriptLoaded(true);

    })
	
	console.log("requesting teams list");
	(webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.teamsList);
  }, []);

  useEffect(() => {
    console.log("map updated " + map);

    map && queuedMarkers.forEach((tempMarker) => {
      console.log("putting on map " + tempMarker.title);
      tempMarker.setMap(map);
    });
  }, [map]);

  useEffect(() => {
    if (scriptLoaded) {

      if (!map)
        return;

      if (!mapCenterSet) {
        map.setCenter(new google.maps.LatLng(location.latitude, location.longitude));
        setMapCenterSet(true);
		webSocket.send(MessageTypes.getGameState);
		        google.maps.event.addListenerOnce(map, 'idle', function(){
				  setPlayerMessage("Loading...");
				  setTimeout(function(){
					setDisableStart("");
					setPlayerMessage("Press the 'Ready' button!");
			}, 2000);
		
        });

      }

      if (!meMarker) {
        setMeMarker(new google.maps.Marker({
          position: new google.maps.LatLng(location.latitude, location.longitude),
          map,
          title: "Me",
          icon: { url: meIcon, anchor: new google.maps.Point(16, 16) }
        }));
      } else {
        meMarker.setPosition(new google.maps.LatLng(location.latitude, location.longitude));
		
      }
		if (Math.abs(map.getHeading() - orientation) > 7) {
			map.setHeading(orientation);
			
		}

      ((webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.locationChangedMessageType + " " + myID + " " + myNumericalHealth + " " + location.latitude + " " + location.longitude));
    }

  }, [location]);

  useEffect(() => {
    if (isRunning) {
      let closestObjectDistance = Number.MAX_VALUE;
      let tempClosestObject = null;

      weaponDropPoints.forEach((weaponDropPoint) => {
        if (weaponDropPoint.active && weaponDropPoint.weapon && weaponDropPoint.location) {
          if (((weaponDropPoint.location.lat >= location.latitude - pickupRange) && (weaponDropPoint.location.lat <= location.latitude + pickupRange))
            && ((weaponDropPoint.location.lng >= location.longitude - pickupRange) && (weaponDropPoint.location.lng <= location.longitude + pickupRange))) {
            pickupWeaponDropPoint(weaponDropPoint.weapon);
            weaponDropPoint.active = false;
            webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + myID + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
          } else {
            if (!tempClosestObject) {
              tempClosestObject = { location: weaponDropPoint.location, name: weaponDropPoint.weapon.name };
              closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: weaponDropPoint.location.lat, lng: weaponDropPoint.location.lng });
            } else {
              let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: weaponDropPoint.location.lat, lng: weaponDropPoint.location.lng });
              if (distance < closestObjectDistance) {
                tempClosestObject = { location: weaponDropPoint.location, name: weaponDropPoint.weapon.name };
                closestObjectDistance = distance;
              }
            }
          }
        }
      });

      supplyDropPoints.forEach((supplyDropPoint) => {
        if (supplyDropPoint.active && supplyDropPoint.supply && supplyDropPoint.location) {
          if (((supplyDropPoint.location.lat >= location.latitude - pickupRange) && (supplyDropPoint.location.lat <= location.latitude + pickupRange))
            && ((supplyDropPoint.location.lng >= location.longitude - pickupRange) && (supplyDropPoint.location.lng <= location.longitude + pickupRange))) {
            pickupSupplyDropPoint(supplyDropPoint.supply);
            supplyDropPoint.supply = null;
            //supplyDropPoint.active = false;
            webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + myID + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
          } else {
            if (!tempClosestObject) {
              tempClosestObject = { location: supplyDropPoint.location, name: supplyDropPoint.supply.name };
              closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: supplyDropPoint.location.lat, lng: supplyDropPoint.location.lng });
            } else {
              let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: supplyDropPoint.location.lat, lng: supplyDropPoint.location.lng });
              if (distance < closestObjectDistance) {
                tempClosestObject = { location: supplyDropPoint.location, name: supplyDropPoint.supply.name };
                closestObjectDistance = distance;
              }
            }
          }
        }
      });

      placedWeapons.forEach((weapon) => {
        if (((weapon.location.latitude >= location.latitude - pickupRange) && (weapon.location.latitude <= location.latitude + pickupRange))
          && ((weapon.location.longitude >= location.longitude - pickupRange) && (weapon.location.longitude <= location.longitude + pickupRange))) {
          pickupWeapon(weapon);
        } else {
          if (!tempClosestObject) {
            tempClosestObject = { location: weapon.location, name: weapon.name };
            closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: weapon.location.latitude, lng: weapon.location.longitude });
          } else {
            let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: weapon.location.latitude, lng: weapon.location.longitude });
            if (distance < closestObjectDistance) {
              tempClosestObject = { location: weapon.location, name: weapon.name };
              closestObjectDistance = distance;
            }
          }
        }
      });

      placedAmmo.forEach((ammo) => {
        if (((ammo.location.latitude >= location.latitude - pickupRange) && (ammo.location.latitude <= location.latitude + pickupRange))
          && ((ammo.location.longitude >= location.longitude - pickupRange) && (ammo.location.longitude <= location.longitude + pickupRange))) {
          pickupAmmo(ammo);
        } else {
          if (!tempClosestObject) {
            tempClosestObject = { location: ammo.location, name: ammo.name };
            closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: ammo.location.latitude, lng: ammo.location.longitude });
          } else {
            let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: ammo.location.latitude, lng: ammo.location.longitude });
            if (distance < closestObjectDistance) {
              tempClosestObject = { location: ammo.location, name: ammo.name };
              closestObjectDistance = distance;
            }
          }
        }
      });

      teams.forEach((tempTeam) => {
        //console.log("checking flag " + tempTeam.name + " " + (tempTeam.id != team.id) + " " + (tempTeam.flag.location != null));
        if (tempTeam.id != team.id) {
          if (tempTeam.flag.location != null && myNumericalHealth != HealthValues.Dead) {
            //console.log("checking for pick up " + tempTeam.flag.location.lat + " " + location.latitude + " " + pickupRange);
            //console.log(tempTeam.flag);
            if (((tempTeam.flag.location.lat >= location.latitude - pickupRange) && (tempTeam.flag.location.lat <= location.latitude + pickupRange))
              && ((tempTeam.flag.location.lng >= location.longitude - pickupRange) && (tempTeam.flag.location.lng <= location.longitude + pickupRange))) {
              //console.log("picking up flag");

              tempTeam.flag.location = null;
              tempTeam.flag.badguyCarrying = myID;
              tempTeam.flag.marker = null;
			  meMarker.setIcon(otherTeamPlayerYourFlagIcon);

              var pickupAudio = document.getElementById('pickupAudio');
              pickupAudio.pause();
              pickupAudio.currentTime = 0;
              pickupAudio.play();

              //console.log(teams);
              (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.updateTeamFlagCarryMessageType + " " + "0" + " " + tempTeam.id + " " + myID);
            } else {
              if (!tempClosestObject) {
                tempClosestObject = { location: tempTeam.flag.location, name: tempTeam.name };
                closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: tempTeam.flag.location.lat, lng: tempTeam.flag.location.lng });
              } else {
                let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: tempTeam.flag.location.lat, lng: tempTeam.flag.location.lng });
                if (distance < closestObjectDistance) {
                  tempClosestObject = { location: tempTeam.flag.location, name: tempTeam.name };
                  closestObjectDistance = distance;
                }
              }
            }
          }
        } else {
          console.log("my team");
          if (tempTeam.baseLocation) {
            console.log("my team base location");
            if (((tempTeam.baseLocation.lat >= location.latitude - pickupRange) && (tempTeam.baseLocation.lat <= location.latitude + pickupRange))
              && ((tempTeam.baseLocation.lng >= location.longitude - pickupRange) && (tempTeam.baseLocation.lng <= location.longitude + pickupRange))) {
              console.log("my team base location within");
              setMyNumericalHealth(HealthValues.Alive);

              teams.forEach((tempTeam2) => {
                if (tempTeam2.id != team.id) {
                  if (tempTeam2.flag.badguyCarrying && tempTeam2.flag.badguyCarrying === myID && myNumericalHealth != HealthValues.Dead) {
                    setPlayerMessage("Your team won!");
					console.log("YOU WON!");
                    processWin();
                  }
                }
              });
            } else {
              if (!tempClosestObject) {
                tempClosestObject = { location: tempTeam.baseLocation, name: "your base" };
                closestObjectDistance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: tempTeam.baseLocation.lat, lng: tempTeam.baseLocation.lng });
              } else {
                let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: tempTeam.baseLocation.lat, lng: tempTeam.baseLocation.lng });
                if (distance < closestObjectDistance) {
                  tempClosestObject = { location: tempTeam.baseLocation, name: "your base" };
                  closestObjectDistance = distance;
                }
              }
            }
          }
        }
      });

      tempClosestObject && setClosestObject({ location: tempClosestObject.location, name: tempClosestObject.name, distance: closestObjectDistance });
    }
  }, [isRunning, placedAmmo, placedWeapons, location]);

  useEffect(() => {
    if (scriptLoaded && location && orientation) {
      if (equippedWeapon instanceof DirectFireWeapon) {
        indirectFireMarker && indirectFireMarker.setMap(null);

        var bearingMarkerEndPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(location.latitude, location.longitude), equippedWeapon.range, orientation);
        var bearingCoordinates = [
          { lat: location.latitude, lng: location.longitude },
          bearingMarkerEndPoint,
        ];

        if (!directFireMarker) {
          setDirectFireMarker(new google.maps.Polyline({
            path: bearingCoordinates,
            geodesic: true,
            strokeColor: equippedWeapon.bearingMarkerColor,
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map: map,
          }));
        } else {
          directFireMarker.setPath(bearingCoordinates);
          directFireMarker.setOptions({ strokeColor: equippedWeapon.bearingMarkerColor });
          directFireMarker.setMap(map);
        }
      } else if (equippedWeapon instanceof IndirectFireWeapon) {
        directFireMarker && directFireMarker.setMap(null);

        console.log("Indirect fire marker " + equippedWeapon.range + " " + equippedWeapon.bearingMarkerColor + " " + equippedWeapon.explosiveRange);

        var bearingMarkerEndPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(location.latitude, location.longitude), equippedWeapon.range, orientation);

        if (!indirectFireMarker) {
          setIndirectFireMarker(new google.maps.Circle({
            strokeColor: equippedWeapon.bearingMarkerColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: equippedWeapon.bearingMarkerColor,
            fillOpacity: 0.35,
            map: map,
            center: bearingMarkerEndPoint,
            radius: equippedWeapon.explosiveRange,
          }));
        } else {
          indirectFireMarker.setOptions({
            strokeColor: equippedWeapon.bearingMarkerColor,
            fillColor: equippedWeapon.bearingMarkerColor,
            radius: equippedWeapon.explosiveRange
          });
          indirectFireMarker.setCenter(bearingMarkerEndPoint);
          indirectFireMarker.setMap(map);
        }
      }
    }
  }, [equippedWeapon, orientation, location]);

  /*useEffect(() => {
    ((webSocket.readyState === WebSocket.OPEN) && orientation && webSocket.send(MessageTypes.debugLocationOrientationMessageType + " " + myID + " " + location.latitude + " " + location.longitude + " " + location.accuracy + " " + location.timestamp + " " + orientation));
  }, [orientation, location]);*/

  useEffect(() => {
    if (myNumericalHealth === HealthValues.Dead) {
      processDead();
    }
  }, [myNumericalHealth]);

  useEffect(() => {
    if (gameStarted && calculateDropPoints) {
      if (weaponDropPoints[0].active && weaponDropPoints[0].weapon === null) {
        if (Math.random() < 0.5) {
          weaponDropPoints[0].weapon = grenadeLauncher;
          weaponDropPoints[1].weapon = mortar;
        } else {
          weaponDropPoints[1].weapon = grenadeLauncher;
          weaponDropPoints[0].weapon = mortar;
        }

        webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + myID + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
      }

      // Supplies
      // For each supply point, start a timer of 1 minute
      // at each timer tick:
      // 1 in 3 chance ammo
      // if not ammo, 1 in 3 chance med kit
      // if not med kit, 1 in 3 chance shield

      setInterval(() => {
        console.log("running timer");
        setTimer(timer => timer + 1)
      }, 5000);

    }
  }, [gameStarted, calculateDropPoints]);

  useEffect(() => {
    console.log("timer tic");
    supplyDropPoints.forEach((supplyDropPoint) => {
      if (supplyDropPoint.active && supplyDropPoint.supply === null) {
        if (Math.random() < 0.33) {
          console.log("ammo");
          sendAmmo(supplyDropPoint);
        } else {
          console.log("no ammo");
        }/* if(Math.random()<0.33){
      
    }else if(Math.random()<0.33){
      
    }*/
      }
    });

  }, [timer]);

  function sendAmmo(supplyDropPoint) {
    const ammoTypes = ["9mm", "5.56", "40mm_Grenade", "80mm_Mortar"];
    const name = ammoTypes[Math.floor(Math.random() * ammoTypes.length)];
    const ammount = (Math.floor(Math.random() * 10) + 1) * 3;
    supplyDropPoint.supply = new PlacedAmmo(name, ammount, supplyDropPoint.location, null);
    webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + myID + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
  }

  /*useEffect(() => {
    ((webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.joinedTeamMessageType + " " + myID + " " + userName + " " + team.id));
  }, [userName, team]);*/

  const handleSuccess = e => {
    if (e.alpha)
      setOrientation(e.webkitCompassHeading || Math.abs(e.alpha - 360));
  };

  function toggleModal(e) {
    e.preventDefault();

    if (document.getElementById('userName').value === "") {
      setLoginMessage("Please enter your player name.");
      return;
    }

    if (document.getElementById('userName').value.includes(" ")) {
      setLoginMessage("No spaces in player name.");
      return;
    }

    const teamRadioButtons = document.querySelectorAll('input[name="team"]');
    let selectedTeam;
    for (const radioButton of teamRadioButtons) {
      if (radioButton.checked) {
        selectedTeam = radioButton.value;
        break;
      }
    }

    if (!selectedTeam) {
      setLoginMessage("Please select a team.");
      return;
    }

    setUserName(document.getElementById('userName').value);
    localStorage.setItem("userName", document.getElementById('userName').value);

    for (const team of teams) {
      if (selectedTeam == team.id) {
        setTeam(team);
        break;
      }
    }

    ((webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.joinedTeamMessageType + " " + myID + " " + document.getElementById('userName').value + " " + selectedTeam));

    setIsModalOpen(!isModalOpen);
  }

  function sameTeam(id) {
    if (!team)
      return false;
    for (const otherPlayer of team.players) {
      if (otherPlayer.id === id) {
        return true;
      }
    }
    return false;
  }

  function testToggleCallback() {
    setTest(!isTest);
  }

  function settingsToggleCallback() {
    setSettings(!isSettings);

    if (!isSettings) {
      document.getElementById('settingsDiv').style.visibility = 'visible';
    } else {
      document.getElementById('settingsDiv').style.visibility = 'hidden';
    }
  }

  function submitSettings() {
    setOrientationRange(Number(document.getElementById('orientationRange').value));
    setPickupRange(Number(document.getElementById('pickupRange').value));
    m4.range = Number(document.getElementById('m4Range').value);
    pistol.range = Number(document.getElementById('pistolRange').value);
    grenadeLauncher.range = Number(document.getElementById('glRange').value);

    console.log(orientationRange + " " + pickupRange + " " + m4.range + " " + pistol.range + " " + grenadeLauncher.range);
  }

  function adminToggleCallback() {
    setAdmin(!isAdmin);

    //this feels wrong, should be if(isAdmin) but very temporary
    if (!isAdmin) {
      document.getElementById('settings').style.visibility = 'visible';
      document.getElementById('test').style.visibility = 'visible';
      document.getElementById('start').style.visibility = 'visible';
      document.getElementById('newTarget').style.visibility = 'visible';
      document.getElementById('newAmmoDropPoint').style.visibility = 'visible';
      document.getElementById('reviveTargets').style.visibility = 'visible';
      document.getElementById('setM4').style.visibility = 'visible';
      document.getElementById('setGrenadeLauncher').style.visibility = 'visible';
      document.getElementById('setMortar').style.visibility = 'visible';
      document.getElementById('shoot').style.visibility = 'hidden';
      document.getElementById('demo').style.visibility = 'visible';

      placedAmmoDropPoints.forEach((ammoDropPoint) => {
        ammoDropPoint.marker.setMap(map);
      });

      if (isSettings) {
        document.getElementById('settingsDiv').style.visibility = 'visible';
      } else {
        document.getElementById('settingsDiv').style.visibility = 'hidden';
      }
    } else {
      document.getElementById('settings').style.visibility = 'hidden';
      document.getElementById('test').style.visibility = 'hidden';
      document.getElementById('start').style.visibility = 'hidden';
      document.getElementById('newAmmoDropPoint').style.visibility = 'hidden';
      document.getElementById('newTarget').style.visibility = 'hidden';
      document.getElementById('reviveTargets').style.visibility = 'hidden';
      document.getElementById('setM4').style.visibility = 'hidden';
      document.getElementById('setGrenadeLauncher').style.visibility = 'hidden';
      document.getElementById('setMortar').style.visibility = 'hidden';
      document.getElementById('shoot').style.visibility = 'visible';
      document.getElementById('demo').style.visibility = 'hidden';

      placedAmmoDropPoints.forEach((ammoDropPoint) => {
        ammoDropPoint.marker.setMap(null);
      });

      document.getElementById('settingsDiv').style.visibility = 'hidden';
    }
  }

  function start() {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      DeviceOrientationEvent.requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('deviceorientation', handleSuccess);
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
      window.addEventListener('deviceorientationabsolute', handleSuccess);
    }

    weaponsOnHand.push(pistol, m4);
    document.getElementById("start").disabled = true;
    document.getElementById("start").style.backgroundColor = "green";

    setInterval(newPlacedAmmo, 2500);//(3 * 60 * 1000));

    setIsRunning(true);
  }
  


  function processWin() {  
  webSocket.send(MessageTypes.teamWinMessageType + " " + "0" + " " + team.id);
  setHideNext(false);
  setDoIHaveEnemyFlag(false);
    if (meMarker) {
      console.log("Changing icon to meIcon");
      meMarker.setIcon(meIcon);
    }
  
  }

  function pickupWeaponDropPoint(weapon) {
    console.log("picked up " + weapon.name);
    weaponsOnHand.push(weapon);

    var pickupAudio = document.getElementById('pickupAudio');
    pickupAudio.pause();
    pickupAudio.currentTime = 0;
    pickupAudio.play();
  }

  function pickupSupplyDropPoint(supply) {
    console.log("picked up " + supply.name);
    for (const weapon of weaponsOnHand) {
      if (weapon.ammoName === supply.name) {
        weapon.ammoCount += supply.ammount;
        break;
      }
    }

    var pickupAudio = document.getElementById('pickupAudio');
    pickupAudio.pause();
    pickupAudio.currentTime = 0;
    pickupAudio.play();
  }

  function pickupWeapon(weapon) {
    console.log("picked up " + weapon.weapon.name);
    placedWeapons.splice(placedWeapons.indexOf(weapon), 1);
    weapon.marker.setMap(null);
    weaponsOnHand.push(weapon.weapon);
  }

  function pickupAmmo(ammo) {
    console.log("picked up " + ammo.ammount + " " + ammo.name);
    placedAmmo.splice(placedAmmo.indexOf(ammo), 1);
    ammo.marker.setMap(null);

    for (const weapon of weaponsOnHand) {
      if (weapon.ammoName === ammo.name) {
        weapon.ammoCount += ammo.ammount;
        break;
      }
    }
  }

  function newAmmoDropPoint() {
    var ammoDropPointMarker = new google.maps.Marker({
      position: new google.maps.LatLng(location.latitude + (isTest ? .00001 * (placedAmmoDropPoints.length + 1) : 0), location.longitude),
      map,
      title: "Ammo Drop Point " + placedAmmoDropPoints.length,
      icon: { url: "https://labs.google.com/ridefinder/images/mm_20_purple.png" }
    });

    let ammoDropPoint = new AmmoDropPoint({ latitude: location.latitude + (isTest ? .00001 * (placedAmmoDropPoints.length + 1) : 0), longitude: location.longitude }, ammoDropPointMarker);
    placedAmmoDropPoints.push(ammoDropPoint);
  }

  function newPlacedAmmo() {
    if (placedAmmoDropPoints.length > 0) {
      const ammoTypes = ["9mm", "5.56", "40mm_Grenade", "80mm_Mortar"];
      const name = ammoTypes[Math.floor(Math.random() * ammoTypes.length)];
      const ammount = (Math.floor(Math.random() * 10) + 1) * 3;
      const randLocation = placedAmmoDropPoints[Math.floor(Math.random() * placedAmmoDropPoints.length)].location;
      var marker = new google.maps.Marker({
        position: new google.maps.LatLng(randLocation.latitude, randLocation.longitude),
        map,
        title: "Ammo " + name,
        icon: { url: orangeBoxIcon, anchor: new google.maps.Point(16, 16) }
      });

      var newPlacedAmmo = new PlacedAmmo(name, ammount, randLocation, marker);
      placedAmmo.push(newPlacedAmmo);
    }

  }

  function newTarget() {
    createNewTarget(location.latitude + (isTest ? .00001 * (uniqueId + 1) : 0), location.longitude);
  }

  function createNewTarget(lat, lng) {
    var id2 = uniqueId;
    setUniqueId(uniqueId + 1);

    var targetMarker = new google.maps.Marker({
      position: new google.maps.LatLng(lat, lng),
      map,
      title: "Target " + id2,
      icon: { url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png" }
    });

    let target = new Target(id2, { latitude: lat, longitude: lng }, targetMarker, true);
    targets.push(target);

    google.maps.event.addListener(targetMarker, 'click', function () {
      var content = '<div>ID: ' + target.id + '<br>Health: ' + (target.health ? 'Alive' : 'Dead') + '<br>Latitude: ' + target.location.latitude + '<br />Longitude: ' + target.location.longitude;
      content += '<br /><input type = "button" id="delete" value = "Delete" />';
      if (target.health === false)
        content += '<input type = "button" id="revive" value = "Revive" />';
      else
        content += '<input type = "button" id="kill" value = "Kill" />';
      content += '</div>'
      infoWindow = new google.maps.InfoWindow({
        content: content
      });
      infoWindow.open(map, targetMarker);

      google.maps.event.addListener(infoWindow, "domready", function () {
        const deleteButton = document.getElementById("delete");
        if (deleteButton) {
          deleteButton.addEventListener('click', function () { deleteTarget(target.id) });
        }
        if (target.health === false)
          document.getElementById("revive").addEventListener('click', function () { reviveTarget(target.id) });
        else
          document.getElementById("kill").addEventListener('click', function () { killTarget(target.id) });
      });

    });
  }

  var infoWindow;

  function deleteTarget(id) {
    for (var i = 0; i < targets.length; i++) {
      if (targets[i].id == id) {
        targets[i].marker.setMap(null);
        targets.splice(i, 1);
        return;
      }
    }
  }

  function reviveTarget(id) {
    for (var i = 0; i < targets.length; i++) {
      if (id != -1) {
        if (targets[i].id == id) {
          targets[i].health = true;
          targets[i].marker.setIcon('https://maps.google.com/mapfiles/ms/icons/red-dot.png');
          infoWindow.close();
          return;
        }
      } else {
        targets[i].health = true;
        targets[i].marker.setIcon('https://maps.google.com/mapfiles/ms/icons/red-dot.png');
      }

    }
  }

  function killTarget(id) {
    for (var i = 0; i < targets.length; i++) {
      if (targets[i].id == id) {
        targets[i].health = false;
        targets[i].marker.setIcon('https://maps.google.com/mapfiles/ms/icons/yellow-dot.png');
        infoWindow && infoWindow.close();
        return;
      }
    }
  }

  function killOtherPlayer(id, damage) {
    console.log("kill other player " + id + " " + damage);
    webSocket.send(MessageTypes.killOtherPlayerMessageType + " " + myID + " " + id + " " + damage)
  }

  function setIed() {

  }

  function setM4() {
    document.getElementById('setM4').disabled = true;
    var m4Marker = new google.maps.Marker({
      position: new google.maps.LatLng(location.latitude, location.longitude),
      map,
      title: "M4",
      icon: { url: greyBoxIcon }
    });
    var placedM4 = new PlacedWeapon(m4, location, m4Marker);
    placedWeapons.push(placedM4);
  }

  function setGrenadeLauncher() {
    document.getElementById('setGrenadeLauncher').disabled = true;
    var grenadeLauncherMarker = new google.maps.Marker({
      position: new google.maps.LatLng(location.latitude, location.longitude),
      map,
      title: "Grenade Launcher",
      icon: { url: greyBoxIcon, anchor: new google.maps.Point(16, 16) }
    });
    var placedGrenadeLauncher = new PlacedWeapon(grenadeLauncher, location, grenadeLauncherMarker);
    placedWeapons.push(placedGrenadeLauncher);
  }

  function setMortar() {
    document.getElementById('setMortar').disabled = true;
    var mortarMarker = new google.maps.Marker({
      position: new google.maps.LatLng(location.latitude, location.longitude),
      map,
      title: "Mortar",
      icon: { url: greyBoxIcon, anchor: new google.maps.Point(16, 16) }
    });
    var placedMortar = new PlacedWeapon(mortar, location, mortarMarker);
    placedWeapons.push(placedMortar);
  }

  function shoot() {
    console.log("shoot");
    if (myNumericalHealth === HealthValues.Dead)
      return;

    if (equippedWeapon.ammoCount > 0) {
      var gunShotAudio = document.getElementById(equippedWeapon.soundSource);
      gunShotAudio.pause();
      gunShotAudio.currentTime = 0;
      gunShotAudio.play();

      if (equippedWeapon instanceof DirectFireWeapon) {
        console.log(equippedWeapon.range);
        let bearing = -1;
        targets.forEach((target) => {
          bearing = calculateBearing(location, target.location);
          if ((bearing >= orientation - orientationRange) && (bearing <= orientation + orientationRange)) {
            if (google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: target.location.latitude, lng: target.location.longitude }) < equippedWeapon.range) {
              killTarget(target.id);
            }
          }
        });
        otherPlayers.forEach((target) => {
          let triangleCoords = [
            { lat: location.latitude, lng: location.longitude },
            google.maps.geometry.spherical.computeOffset({ lat: location.latitude, lng: location.longitude }, equippedWeapon.range, orientation - orientationRange),
            google.maps.geometry.spherical.computeOffset({ lat: location.latitude, lng: location.longitude }, equippedWeapon.range, orientation + orientationRange),
          ];
          let hitTriangle = new google.maps.Polygon({ paths: triangleCoords });

          if (google.maps.geometry.poly.containsLocation({ lat: target.location.latitude, lng: target.location.longitude }, hitTriangle)) {
            killOtherPlayer(target.id, equippedWeapon.damage);
          }

          /*bearing = calculateBearing(location, target.location);
          if ((bearing >= orientation - orientationRange) && (bearing <= orientation + orientationRange)) {
            let distance = google.maps.geometry.spherical.computeDistanceBetween({ lat: location.latitude, lng: location.longitude }, { lat: target.location.latitude, lng: target.location.longitude });
            if (distance < equippedWeapon.range) {
              killOtherPlayer(target.id, equippedWeapon.damage);
            } /*else if (distance < (1.1 * equippedWeapon.range)) {
              webSocket.send(MessageTypes.nearMissMessageType + " " + myID + " " + target.id + " " + NearMissFields.directFire);
            }*/
          /*} /*else if ((bearing >= orientation - (2 * orientationRange)) && (bearing <= orientation + (2 * orientationRange))) {
            if (distance < (1.1 * equippedWeapon.range)) {
              webSocket.send(MessageTypes.nearMissMessageType + " " + myID + " " + target.id + " " + NearMissFields.directFire);
            }
          }*/
        });

      } else if (equippedWeapon instanceof IndirectFireWeapon) {
        targets.forEach((target) => {
          if (google.maps.geometry.spherical.computeDistanceBetween(indirectFireMarker.getCenter(), { lat: target.location.latitude, lng: target.location.longitude }) < equippedWeapon.explosiveRange) {
            killTarget(target.id);
          }
        });
        otherPlayers.forEach((target) => {
          let distance = google.maps.geometry.spherical.computeDistanceBetween(indirectFireMarker.getCenter(), { lat: target.location.latitude, lng: target.location.longitude });
          if (distance < equippedWeapon.explosiveRange) {
            killOtherPlayer(target.id, equippedWeapon.damage);
          } else if (distance < (1.1 * equippedWeapon.explosiveRange)) {
            webSocket.send(MessageTypes.nearMissMessageType + " " + myID + " " + target.id + " " + NearMissFields.indirectFire);
          }
        });
      }

      equippedWeapon.ammoCount -= equippedWeapon.roundsPerShot;
    }
  }

  function reviveSelf() {
    setMyNumericalHealth(HealthValues.Alive);
  }

  function weaponChanged(name) {
    console.log("weapon changed " + name);
    setEquippedWeapon(weaponsOnHand.find(weapon => { return weapon.name === name }));
    document.getElementById("shoot").disabled = (name === "None");
  }

  function calculateBearing(source, target) {
    const y = Math.sin(target.longitude - source.longitude) * Math.cos(target.latitude);
    const x = Math.cos(source.latitude) * Math.sin(target.latitude) - Math.sin(source.latitude) * Math.cos(target.latitude) * Math.cos(target.longitude - source.longitude);
    const theta = Math.atan2(y, x);

    return (theta * 180 / Math.PI + 360) % 360;
  }

  function placeTeamFlagClicked() {
    console.log("team flag clicked");
    document.getElementById('placeTeamFlag').disabled = true;
    setShowEquipment(false);
  };

  function placeTeamFlag(lat, lng) {
    console.log("setting team flag " + lat + " " + lng);
    document.getElementById('placeTeamFlag').style.visibility = 'hidden';

    //team.flag.location = new google.maps.LatLng(lat, lng);
    //team.flag.playerIdHolding = null;

    //console.log("Teams " + JSON.stringify(teams));

    webSocket.send(MessageTypes.updateTeamBaseLocationMessageType + " " + "0" + " " + team.id + " " + lat + " " + lng);
    webSocket.send(MessageTypes.updateTeamFlagLocationMessageType + " " + "0" + " " + team.id + " " + lat + " " + lng);


	setDoIHaveFlag(false);
	


    /*if (team.flag.marker === null) {
      console.log("creating marker " + team.flag.location);
      var flagMarker = new google.maps.Marker({
        position: new google.maps.LatLng(lat, lng),
        map,
        title: "???",
        icon: { url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png" }
      });
      team.flag.marker = flagMarker;
    } else {
      team.flag.marker.setPosition(new google.maps.LatLng(lat, lng));
    }*/
  };

  function mapClick(lat, lng) {
    var temp = document.getElementById('placeTeamFlag').disabled && document.getElementById('placeTeamFlag').style.visibility === 'visible';

    console.log("map clicked " + temp);

    if (document.getElementById('placeTeamFlag').disabled && document.getElementById('placeTeamFlag').style.visibility === 'visible') {
      console.log("clicked flag " + lat + " " + lng);
      document.getElementById('placeTeamFlag').style.visibility = 'hidden';
      placeTeamFlag(lat, lng);
    }
  }

  function setMyMap(map) {
    map.addListener('click', (event) => {
      mapClick(event.latLng.toJSON().lat, event.latLng.toJSON().lng);

    });
    setMap(map);
  }

  function menu() {
    setShowMenu(true);
  }

  function weapons() {
    setShowWeapons(true);
  }

  function equipment() {
    setShowEquipment(true);
  }
  
  

  return (
    <div className="ScreenD">
      {!userName && (

        <Modal
          isOpen={isModalOpen}
          onRequestClose={toggleModal}
          contentLabel="Login To Quest!"
          shouldCloseOnOverlayClick={false}
          className="wrapper1"
        >

          <div className="formContent1">
            <h2 className="title"> Quest </h2>

            <form onSubmit={toggleModal}>
              <label htmlFor="userName">User Name</label>
              <input className="second username" type="text" id="userName" name="userName" defaultValue={localStorage.getItem("userName")} /><br />
			  
              <div>
                {teams.map(team => {
                  return (
                    <label key={team.id + "Label"}>
                      <input type="radio" key={team.id} value={team.id} name="team" /> {team.name}
                    </label>
                  );
                })}
              </div>

              <input type="submit" className="fourth customBtn" value="Login" />
              <br />
              <label style={{ color: 'black' }}>{loginMessage}</label>
            </form>
          </div>

        </Modal>
      )}

      <div id="placeTeamFlag" />

      {userName && (
        <div>
          <header className="ScreenD-header">
            <audio id="gunShotAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0159/watermarked/SciFi%206079_96_3_preview.mp3"></audio>
            <audio id="m4ShotAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0150/watermarked/Rifle%20GUNS02_17_3_preview.mp3"></audio>
            <audio id="grenadeLauncherShotAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0102/watermarked/GunAntiTank%206025_29_1_preview.mp3"></audio>
            <audio id="mortarShotAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0082/watermarked/EXPLOSION-LARGE_GEN-HDF-10854_preview.mp3"></audio>

            <audio id="directFireMissAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0102/watermarked/GunAntiTank%206025_29_1_preview.mp3"></audio>
            <audio id="indirectFireMissAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0102/watermarked/GunAntiTank%206025_29_1_preview.mp3"></audio>

            <audio id="deadAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0130/watermarked/MaleLngFallScream%20PE675301_preview.mp3"></audio>

            <audio id="winAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0070/watermarked/CrowdCheersSchoolCelebrationwi%20PEHD093201_preview.mp3"></audio>
            <audio id="loseAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0073/watermarked/DarkDramaAccents%20EC10_34_3_preview.mp3"></audio>

            <audio id="ambientAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0074/watermarked/DeepSpaceAmbSpace%20EXD015001_preview.mp3"></audio>

            <audio id="pickupAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0102/watermarked/GunCockSingle PE1096222_preview.mp3"></audio>

            
            <div className="user-info-container">
              {userName && <label>{userName}</label>}
              {team && <label>Team: {team.name}</label>}
            </div>

            <div className='grid-item'>
             <button id="start" onClick={start} style={{ backgroundColor: "red" }} className="button" disabled={disableStart}>Ready</button>
			</div>

          </header>
		  

          <div>
            <Health health={myNumericalHealth} />
            <label className="label1">Ammo: {equippedWeapon.name === "None" ? "0" : equippedWeapon.ammoCount} </label>
            
			<label className="label1">Messages: {playerMessage ? playerMessage : ""}</label>
           
          </div>

          {scriptLoaded && (
            <MyMap mapType={google.maps.MapTypeId.ROADMAP} mapTypeControl={true} mapSetCallback={setMyMap} />
          )}

          <div className='button-container'>
            <div className='grid-item'><button id="equipment" onClick={equipment} className="button">Tool Box</button></div>
            <div className='grid-item'><button id="shoot" onClick={shoot} disabled={equippedWeapon.name === "None"} className="button2">Shoot</button></div>
            <div className='grid-item'><button id="weapons" onClick={weapons} className="button">Weapons</button></div>
            <div className='grid-item' />
            <div className='grid-item'><button id="start game" onClick={startGame} style={{ backgroundColor: "red" }} className="button">Start Game</button></div>
				{/* <div className='grid-item'><button id="menu" onClick={menu} className="button3">Menu</button></div> */}
					<WebSocketComponent/> 
					<BackgroundAudio />
					
                 {/* <p><strong>Altitude:</strong> {location.altitude !== null ? `${location.altitude} meters` : 'Altitude not available'}</p> */}
			
		  </div>
		  <br />
		  <div>
		  {!hideNext ? ( 
			<Link to="/Admin" className="App-link"> See the Game Results! </Link>
			) : ( "See the Game Results!" ) }
		  </div>

          <Modal
            isOpen={showWeapons}
            contentLabel="Weapons"
            shouldCloseOnOverlayClick={false}
            className="wrapper"
          >
            <div className="formContent">
              <div>
                {weaponsOnHand.map(weapon => {
                  return (
                    <div key={weapon.name + "Div"}>
                      <label key={weapon.name + "Label"}>
                        <input type="radio" key={weapon.name} value={weapon.name} name="weapon" onChange={() => weaponChanged(weapon.name)} checked={equippedWeapon.name === weapon.name} className="radio-hidden" />
                        <img src={require('./components/images/' + weapon.name + '.jpg')} alt={weapon.name} className="weapon-thumbnail" />
                      </label>
                      <br />
                    </div>
                  );
                })}
              </div>

              <button onClick={() => setShowWeapons(false)}className="button">Close Weapons</button>
            </div>
          </Modal>

          <Modal
            isOpen={showEquipment}
            contentLabel="Tool Box"
            shouldCloseOnOverlayClick={false}
            className="wrapper"
          >
            <div className="formContent">
              <button id="placeTeamFlagButton" onClick={placeTeamFlagClicked} disabled={document.getElementById('placeTeamFlag').disabled}className="button">Place Team Flag</button>
              <button id="reviveSelf" onClick={reviveSelf} disabled={myNumericalHealth === HealthValues.Dead}className="button">New Life</button>
			  <br />
              <button onClick={() => setShowEquipment(false)}className="button"className="button">Close Tool Box</button>
            </div>
          </Modal>

          <Modal
            isOpen={showMenu}
            contentLabel="Menu"
            shouldCloseOnOverlayClick={false}
            className="wrapper"
          >

            <div className="formContent">
              <label id="settings">
                <input type="checkbox" defaultChecked={isSettings} onClick={settingsToggleCallback} />
                <span />
                <strong>Weapon Settings</strong>
              </label>
              <br />
              <button id="newAmmoDropPoint" onClick={newAmmoDropPoint}className="button">New Ammo Drop Point</button>
              <button id="setGrenadeLauncher" onClick={setGrenadeLauncher}className="button">Set G.L.</button>
              <button id="setMortar" onClick={setMortar}className="button">Set Mortar</button>
              <br />
              <button id="reviveSelf" onClick={reviveSelf} disabled={myNumericalHealth === HealthValues.Dead}className="button">Resurrect Self</button>
              <br />
              <button onClick={() => setShowMenu(false)}className="button">Close Menu</button>
            </div>
          </Modal>

          <div id="settingsDiv" className="hidden">

            <label htmlFor="orientationRange">Hit Radius </label>
            <input type="text" id="orientationRange" name="orientationRange" defaultValue={orientationRange} /><br />
            <label htmlFor="m4Range">M4 Range </label>
            <input type="text" id="m4Range" name="m4Range" defaultValue={m4.range} /><br />
            <label htmlFor="pistolRange">Pistol Range </label>
            <input type="text" id="pistolRange" name="pistolRange" defaultValue={pistol.range} /><br />

            <label htmlFor="glRange">GL Range </label>
            <input type="text" id="glRange" name="glRange" defaultValue={grenadeLauncher.range} /><br />


            <label htmlFor="pickupRange">Pickup Radius </label>
            <input type="text" id="pickupRange" name="pickupRange" defaultValue={pickupRange} /><br />
            <button id="submit" onClick={submitSettings}>Submit</button>

            <Location location={location} />
            <Orientation orientation={orientation} />
			
          </div>
        </div>

	

      )}
    </div>
  );
}
/*



            <div id="demo">
              {myID && <label>{myID}</label>}
              <br />
              {userName && <label>{userName}</label>}
              <br />
              {team && <label>{team.name}</label>}
              <br />
              <label>{myNumericalHealth}</label>
              <Location location={location} />
              <Orientation orientation={orientation} />
            </div>

            
            

          </div>
        </div>
      )}
    </div>
  );
}*/

export default ScreenD;
