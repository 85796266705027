import React from "react";
import PropTypes from "prop-types";
import useWatchLocation from "./hooks/useWatchLocation";

const Location = ({ location, error }) => {
  return (
    <div>
      {location ? (
        <code>
          Time: {location.timestamp}, Latitude: {location.latitude}, Longitude: {location.longitude}, Accuracy: {location.accuracy}
        </code>
      ) : (
        <p>Loading...the</p>
      )}
      {error && <p className="errorMessage">Location Error: {error}</p>}
    </div>
  );
};

Location.propTypes = {
  location: PropTypes.object,
  error: PropTypes.string,
};

export default Location;