export namespace MessageFields{
    export const fieldSeperator = " ";

    export const messageType = 0;
    export const messageId = 1;

    export const serverId = -1;
}

export namespace MessageTypes
{
    export const locationChangedMessageType = "0";
    export const clientDisconnectedMessageType = "1";
    export const killOtherPlayerMessageType = "2";
    export const updateTeamsMessageType = "3";
    export const updateTeamFlagLocationMessageType = "4";
    export const joinedTeamMessageType = "5";
    export const updateTeamFlagCarryMessageType = "6";
    export const resetServerMessageType = "7";
    export const nearMissMessageType = "8";
    export const debugLocationOrientationMessageType = "9";
    export const gameSettingsMessageType = "10";
    export const updateTeamBaseLocationMessageType = "11";
    export const teamWinMessageType = "12";
    export const dropPointMessageType = "13";
    export const startGameMessageType = "14";
	export const teamsList = "15";
	export const getGameState = "16";
}

export namespace LocationChangedFields
{
    export const health = 2;
    export const latitude = 3;
    export const longitude = 4;
}

export namespace ClientDisconnectedFields
{
    export const id = 2;
}

export namespace KillOtherPlayerFields
{
    export const killOtherPlayerId = 2;
    export const damage = 3;
}

export namespace UpdateTeamsFields
{
    export const teamsArray = 2;
}

export namespace UpdateTeamFlagLocationFields
{
    export const teamId = 2;
    export const flagLat = 3;
    export const flagLng = 4;
}

export namespace JoinedTeamFields
{
    export const username = 2;
    export const teamId = 3;
}

export namespace UpdateTeamFlagCarryFields
{
    export const teamId = 2;
    export const playerId = 3;
}

export namespace ResetServerFields
{
}

export namespace NearMissFields
{
    export const playerId = 2;
    export const shotType = 3;

    export const directFire = "0";
    export const indirectFire = "1";
}

export namespace DebugLocationOrientationFields
{    
    export const latitude = 2;
    export const longitude = 3;
    export const accuracy = 4;
    export const timestamp = 5;
    export const orientation = 6;
}

export namespace GameSettingsFields
{    
    export const orientationRange = 2;
    export const pickupRange = 3;
    export const pistolRange = 4;
    export const m4Range = 5;
    export const grenadeLauncherRange = 6;
}

export namespace UpdateTeamBaseLocationFields
{
    export const teamId = 2;
    export const flagLat = 3;
    export const flagLng = 4;
}

export namespace TeamWinFields
{
    export const teamId = 2;
}

export namespace DropPointFields
{
    export const calcId = 2;
    export const weaponPoints = 3;
    export const supplyPoints = 4;
}

export namespace StartGameFields
{

}

export function packMessage(messageAttributes: string[]){
    return messageAttributes.join(MessageFields.fieldSeperator);
}

export function parseMessage(data: string){
    return data.split(MessageFields.fieldSeperator);
}
