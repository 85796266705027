// ScreenF.js
import React, { useState } from 'react';
import background from "./images/space_person_planet.png";


const ScreenF = ({ onScreenChange }) => {
  // State and logic for Screen F

  const handleStartSession = () => {
    // Logic for starting session
    // Assuming successful start, navigate to Screen E-a or E-b based on group
    onScreenChange('A');
    // or onScreenChange('E-b');
  };
  console.log("Rendering Screen F");

   // Style for all displayed text
  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };
  
  // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  // Style for paragraphs
  const paragraphStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '1rem',  // Adjust font size as needed
    // Add other styles as needed
  };

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
	<h1 style={headingStyle}>Screen F</h1>
	<p style={paragraphStyle}>Game Results:</p>
	<p style={paragraphStyle}>-You killed 5 people.</p>
	<p style={paragraphStyle}>-You were shot 10 times.</p>
	<p style={paragraphStyle}>-Your team won.</p>
      {/* Render Screen F UI */}
      <button className='button-select' onClick={handleStartSession}>Play Again</button>
    </div>
  );
};

export default ScreenF;
