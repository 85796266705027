import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import "../App.css";
import useWatchLocation from "../components/hooks/useWatchLocation";
import Location from "../components/Location";
import { loadMapApi } from "../components/utils/GoogleMapsUtils";
import { Link } from 'react-router-dom';
import ScreenD from '../ScreenD.js'; 
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { handleScreenChange } from '../screenChange';
import Modal from 'react-modal'; 


import { MessageFields, MessageTypes, LocationChangedFields, KillOtherPlayerFields, NearMissFields, DebugLocationOrientationFields, DropPointFields } from '../components/utils/WSMessageFormatUtils';

import { v4 as uuidv4 } from 'uuid';


const myID = uuidv4();

const webSocket = new WebSocket(process.env.REACT_APP_WS_URL);


const PlaceStuff = () => {
    const [dropPoints, setDropPoints] = useState([]);
    const [weaponDropPoints, setWeaponDropPoints] = useState([]);
    const [supplyDropPoints, setSupplyDropPoints] = useState([]);
    const [calculateDropPointsId, setCalculateDropPointsId] = useState(-1);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalMessage, setModalMessage] = useState('');
	const [location, setLocation] = useState({ latitude: 0, longitude: 0 });
	
	const [hideNext, setHideNext] = useState(true);
	const [mapCenterSet, setMapCenterSet] = useState(false);
	const [scriptLoaded, setScriptLoaded] = useState(false);

    const [map, setMap] = useState();

	
	  // Style for h1 element
  const headingStyle = {
    color: 'black',  // Text color is set to black
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  

	
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  

  
  const center = useMemo(() => ({ lat: 38.860050651210884, lng: -77.43800010494539 }), []);   


  console.log('Center:', center);
  


  if(weaponDropPoints.length===0){
    (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.getGameState);
	console.log('Obtained game state, socket connection open');
  }

  
  function mapClick(lat, lng){
    console.log("clicked " + lat + " " + lng);

    if(weaponDropPoints.length===0){
        alert("Something failed, reload page");
        return;
    }
    dropPoints.push({ lat, lng });
    //setDropPoints(dropPoints.concat({lat, lng}));
	
	// Determine the appropriate message based on the number of clicks
	if (dropPoints.length <= 1) {
		setModalMessage("You placed weapon drop 1");
	} else if (dropPoints.length ===2) {
		setModalMessage("You placed weapon drop 2");
	} else if (dropPoints.length ===3) {
		setModalMessage("You placed ammunition drop 1");
	} else if (dropPoints.length ===4) {
		setModalMessage("You placed ammunition drop 2");
	} else if (dropPoints.length ===5) {
		setModalMessage("You are good to go to the next screen!");
	}

	// Display the modal
	setModalVisible(true);

	// Close the modal after 2 seconds (2000 milliseconds)
	setTimeout(() => {
		setModalVisible(false);
	}, 2000);
  
    if (dropPoints.length === 5) {
        console.log(weaponDropPoints);
        console.log(supplyDropPoints);

        weaponDropPoints[0].location = dropPoints[0];
        weaponDropPoints[1].location = dropPoints[1];
        supplyDropPoints[0].location = dropPoints[2];
        supplyDropPoints[1].location = dropPoints[3];
        supplyDropPoints[2].location = dropPoints[4];

		setHideNext(false);
        (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + calculateDropPointsId + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
		console.log("Weapons and supply points sent.");
		  // Add a 1-second delay
		  //setTimeout(() => {
			//console.log("socket sent");
			// Your code to execute after the delay
			// This code will be executed after 1 second
		 // }, 1000); // 1000 milliseconds = 1 second
		//} else {
		 // console.error('WebSocket connection is not open.');
		}
        
		//navigate('../ScreenD'); // Update path
		//document.getElementById('placeDropPoints').style.visibility = 'hidden';
        //document.getElementById('placeDropPointsButton').style.visibility = 'hidden';

    }


  webSocket.onmessage = (event) => {
    const messageAttributes = event.data.split(" ");

    //console.log("webSocket " + event.data);
    /*messageAttributes.forEach((attrib) => {
          console.log(attrib);
        });*/


    if (messageAttributes[MessageFields.messageId] === String(myID)) {
        return;
    }
    processMessage(messageAttributes);

};

function processMessage(messageAttributes) {
    switch (messageAttributes[MessageFields.messageType]) {


        case MessageTypes.dropPointMessageType:
            processDropPointMessage(messageAttributes);
            break;
        case MessageTypes.updateTeamsMessageType:
        case MessageTypes.debugLocationOrientationMessageType:
        case MessageTypes.clientDisconnectedMessageType:
        case MessageTypes.locationChangedMessageType:
        case MessageTypes.killOtherPlayerMessageType:
        case MessageTypes.updateTeamFlagLocationMessageType:
        case MessageTypes.updateTeamFlagCarryMessageType:
        case MessageTypes.nearMissMessageType:
        case MessageTypes.joinedTeamMessageType:
        case MessageTypes.resetServerMessageType:
        case MessageTypes.gameSettingsMessageType:
        case MessageTypes.updateTeamBaseLocationMessageType:
        case MessageTypes.teamWinMessageType:
        case MessageTypes.startGameMessageType:
            break;
        default:
            console.log("socket " + messageAttributes[0] + " " + messageAttributes[MessageFields.messageType] + " " + MessageFields.messageType);
    }
}

function processDropPointMessage(messageAttributes) {
    console.log("processing drop point message");
    for(var i=0;i<messageAttributes.length;i++){
        console.log(messageAttributes[i]);
    }
    setCalculateDropPointsId(messageAttributes[DropPointFields.calcId]);

    var tempWeaponDropPoints = JSON.parse(messageAttributes[DropPointFields.weaponPoints]);
    weaponDropPoints.length = 0;
    setWeaponDropPoints(tempWeaponDropPoints);

    var tempSupplyDropPoints = JSON.parse(messageAttributes[DropPointFields.supplyPoints]);
    supplyDropPoints.length = 0;
    setSupplyDropPoints(tempSupplyDropPoints);

}


  return (
    <div className="PlaceStuff">
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
		  zoom={18}
          onClick={(event) => {
            mapClick(event.latLng.toJSON().lat, event.latLng.toJSON().lng)}}
        >
		
        </GoogleMap>
		
      )}
	        {modalVisible && (
      <div className="modal2">
        <p>{modalMessage}</p>
      </div>
    )}
		<br />
		<div>
		<h1 style={headingStyle}>Place Weapons and Ammunition Pick Up Points</h1>       
		</div>
		
		<div>
		Click the map to place weapons (first two clicks) and ammunition (last three clicks). <br /> 
		All will appear when you start the game on the next screen.
		</div>
		<br />
		<div>
		{!hideNext ? ( 
		<Link to="/ScreenD" className="button"> Go to the Game! </Link>
		) : ( "Go to the Game!" ) }
		</div>
	  
	  {/* Modal */}

	  
    </div>
  );
};

export default PlaceStuff;