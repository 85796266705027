// App.js
import React, { useState } from 'react';
import ScreenA from './ScreenA';
import ScreenB from './ScreenB';
import ScreenCa from './ScreenCa';
import ScreenCb from './ScreenCb';
import ScreenD from './ScreenD';
import ScreenE from './ScreenE';
import ScreenF from './ScreenF';
import PlaceStuff from './pages/PlaceStuff';
import './App.css';
import Modal from 'react-modal'; 
import Location from './components/Location';
import { useNavigate } from 'react-router-dom';
import WebSocketComponent from './WebSocketComponent';


const App = () => {
  const [currentScreen, setCurrentScreen] = useState('A');

  const handleScreenChange = (nextScreen) => {
    setCurrentScreen(nextScreen);
  };
  
  
    return (
		<div>
			{currentScreen === 'A' && <ScreenA onScreenChange={handleScreenChange} />}
			{currentScreen === 'placeStuff' && <PlaceStuff onScreenChange={handleScreenChange} />}
			{currentScreen === 'Ca' && <ScreenCa onScreenChange={handleScreenChange} />}
			{currentScreen === 'Cb' && <ScreenCb onScreenChange={handleScreenChange} />}
			{currentScreen === 'D' && <ScreenD onScreenChange={handleScreenChange} />}
			{currentScreen === 'E' && <ScreenE onScreenChange={handleScreenChange} />}
			{currentScreen === 'F' && <ScreenF onScreenChange={handleScreenChange} />}
		</div>
  );
};



export default App;

