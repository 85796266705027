import React, { useEffect, useRef, useState } from 'react';

interface IMap {
    mapType: google.maps.MapTypeId,
    mapTypeControl?: boolean,
    mapSetCallback: Function,
}

type GoogleLatLng = google.maps.LatLng;
type GoogleMap = google.maps.Map;

const MyMap: React.FC<IMap> = ({ mapType, mapTypeControl = false, mapSetCallback }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<GoogleMap>();
    const startMap = (): void => {
        if (!map) {
            defaultMapStart();
        }
    }
    useEffect(startMap, [map]);

    const defaultMapStart = (): void => {
        const defaultAddress = new google.maps.LatLng(37.304, -76.768);
        initMap(19, defaultAddress);
    };

    const initMap = (zoomLevel: number, address: GoogleLatLng): void => {
        if (ref.current) {
            const tempMap = new google.maps.Map(ref.current, {
                zoom: zoomLevel,
                center: address,
                mapTypeControl: mapTypeControl,
                streetViewControl: false,
                zoomControl: true,
                mapTypeId: mapType,
				mapId: "90f87356969d889c"
            });

            mapSetCallback(tempMap);
        }
    };

    return (

        <div className="map-container">
            <div ref={ref} className="map-container_map"></div>
        </div>
    );
};

export default MyMap;