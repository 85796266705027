import React, { Component } from 'react';

class BackgroundAudio extends Component {
  componentDidMount() {
    const audioElement = document.getElementById('ambientAudio');
    audioElement.loop = true;
    audioElement.play();
  }

  componentWillUnmount() {
    const audioElement = document.getElementById('ambientAudio');
    audioElement.pause();
  }

  render() {
    return (
      <audio id="ambientAudio" src="https://v1.cdnpk.net/videvo_files/audio/premium/audio0074/watermarked/DeepSpaceAmbSpace%20EXD015001_preview.mp3"></audio>
    );
  }
}

export default BackgroundAudio;
