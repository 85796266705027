/* eslint-disable */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import MyMap from "../components/MyMap";
import { loadMapApi } from "../components/utils/GoogleMapsUtils";
import Target from "../components/objects/targets/Target"

import { MessageFields, MessageTypes, LocationChangedFields, KillOtherPlayerFields, NearMissFields, DebugLocationOrientationFields, DropPointFields } from '../components/utils/WSMessageFormatUtils';

import { v4 as uuidv4, parse as uuidParse } from 'uuid';
import background from "../images/space_land.png";
import { Link } from 'react-router-dom';


const myID = uuidv4();

const webSocket = new WebSocket(process.env.REACT_APP_WS_URL);

function Admin() {
    const [teams, setTeams] = useState([]);
    const [scriptLoaded, setScriptLoaded] = useState(false);

    const [map, setMap] = useState()

    const [queuedMarkers, setQueuedMarkers] = useState([]);

    const [otherPlayers, setOtherPlayers] = useState(new Map());
    const [otherPlayersAccuracy, setOtherPlayersAccuracy] = useState(new Map());
    const [otherPlayersOrientation, setOtherPlayersOrientation] = useState(new Map());
    const [otherPlayersOrientationRange, setOtherPlayersOrientationRange] = useState(new Map());

    const [orientationRange, setOrientationRange] = useState(3);
    const [pickupRange, setPickupRange] = useState(0.00001);
    const [pistolRange, setPistolRange] = useState(1000);
    const [m4Range, setM4Range] = useState(2000);
    const [grenadeLauncherRange, setGrenadeLauncherRange] = useState(25);

    const [dropPoints, setDropPoints] = useState([]);
    const [weaponDropPoints, setWeaponDropPoints] = useState([]);
    const [supplyDropPoints, setSupplyDropPoints] = useState([]);
    const [calculateDropPointsId, setCalculateDropPointsId] = useState(-1);

    const sameTeamPlayerAliveIcon = "https://maps.google.com/mapfiles/kml/pal4/icon57.png";
    const sameTeamPlayerDeadIcon = "https://maps.google.com/mapfiles/kml/pal4/icon56.png";
    const sameTeamPlayerEnemyFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon59.png";
    const otherTeamPlayerAliveIcon = "https://maps.google.com/mapfiles/kml/pal4/icon49.png";
    const otherTeamPlayerDeadIcon = "https://maps.google.com/mapfiles/kml/pal4/icon48.png";
    const otherTeamPlayerYourFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon51.png";
    const sameTeamBaseIcon = "https://maps.google.com/mapfiles/kml/pal4/icon61.png";
    const otherTeamBaseIcon = "https://maps.google.com/mapfiles/kml/pal4/icon53.png";
    const sameTeamFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon60.png";
    const otherTeamFlagIcon = "https://maps.google.com/mapfiles/kml/pal4/icon52.png";
    const meIcon = "https://maps.google.com/mapfiles/kml/pal3/icon28.png";
    const greyBoxIcon = "https://maps.google.com/mapfiles/kml/pal4/icon11.png";
    const orangeBoxIcon = "https://maps.google.com/mapfiles/kml/pal4/icon12.png";
    const purpleBoxIcon = "https://maps.google.com/mapfiles/kml/pal4/icon13.png";

  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };
  
  // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  // Style for paragraphs
  const paragraphStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '1rem',  // Adjust font size as needed
    // Add other styles as needed
  };





    useEffect(() => {
        console.log("weapon points " + dropPoints.length + " " + weaponDropPoints.length);
        
    }, [weaponDropPoints]);
    
    
    useEffect(() => {
        console.log("updated");
        const googleMapScript = loadMapApi();
        googleMapScript.addEventListener('load', function() {
            setScriptLoaded(true);

        })
    }, []);

    useEffect(() => {
        console.log("map updated " + map);

        map && queuedMarkers.forEach((tempMarker) => {
            console.log("putting on map " + tempMarker.title);
            tempMarker.setMap(map);
        });
    }, [map]);

    webSocket.onmessage = (event) => {
        const messageAttributes = event.data.split(" ");

        //console.log("webSocket " + event.data);
        /*messageAttributes.forEach((attrib) => {
              console.log(attrib);
            });*/


        if (messageAttributes[MessageFields.messageId] === String(myID)) {
            return;
        }
        processMessage(messageAttributes);

    };

    function processMessage(messageAttributes) {
        switch (messageAttributes[MessageFields.messageType]) {


            case MessageTypes.dropPointMessageType:
                processDropPointMessage(messageAttributes);
                break;
            case MessageTypes.updateTeamsMessageType:
                processServerUpdateTeamsMessage(messageAttributes);
                break;
            case MessageTypes.debugLocationOrientationMessageType:
                processDebugLocationOrientationMessage(messageAttributes);
                break;
            case MessageTypes.clientDisconnectedMessageType:
                processClientDisconnectedMessage(messageAttributes)
                break;
            case MessageTypes.locationChangedMessageType:
            case MessageTypes.killOtherPlayerMessageType:
            case MessageTypes.updateTeamFlagLocationMessageType:
            case MessageTypes.updateTeamFlagCarryMessageType:
            case MessageTypes.nearMissMessageType:
            case MessageTypes.joinedTeamMessageType:
            case MessageTypes.resetServerMessageType:
            case MessageTypes.gameSettingsMessageType:
            case MessageTypes.updateTeamBaseLocationMessageType:
            case MessageTypes.teamWinMessageType:
            case MessageTypes.startGameMessageType:
                break;
            default:
                console.log("socket " + messageAttributes[0] + " " + messageAttributes[MessageFields.messageType] + " " + MessageFields.messageType);
        }
    }

    function processDropPointMessage(messageAttributes) {
        console.log("processing drop point message");
        for(var i=0;i<messageAttributes.length;i++){
            console.log(messageAttributes[i]);
        }
        setCalculateDropPointsId(messageAttributes[DropPointFields.calcId]);

        var tempWeaponDropPoints = JSON.parse(messageAttributes[DropPointFields.weaponPoints]);
        weaponDropPoints.length = 0;
        setWeaponDropPoints(tempWeaponDropPoints);

        var tempSupplyDropPoints = JSON.parse(messageAttributes[DropPointFields.supplyPoints]);
        supplyDropPoints.length = 0;
        setSupplyDropPoints(tempSupplyDropPoints);

    }

    function processClientDisconnectedMessage(messageAttributes) {
        if (otherPlayers.has(messageAttributes[MessageFields.messageId])) {
            let target = otherPlayers.get(messageAttributes[MessageFields.messageId]);
            target.marker.setMap(null);
            otherPlayers.delete(messageAttributes[MessageFields.messageId]);
        }

        if (otherPlayersAccuracy.has(messageAttributes[MessageFields.messageId])) {
            let targetAccuracy = otherPlayersAccuracy.get(messageAttributes[MessageFields.messageId]);
            targetAccuracy.marker.setMap(null);
            otherPlayersAccuracy.delete(messageAttributes[MessageFields.messageId]);
        }

        if (otherPlayersOrientation.has(messageAttributes[MessageFields.messageId])) {
            let targetOrientation = otherPlayersOrientation.get(messageAttributes[MessageFields.messageId]);
            targetOrientation.marker.setMap(null);
            otherPlayersOrientation.delete(messageAttributes[MessageFields.messageId]);
        }

        if (otherPlayersOrientationRange.has(messageAttributes[MessageFields.messageId])) {
            let targetOrientationRange = otherPlayersOrientationRange.get(messageAttributes[MessageFields.messageId]);
            targetOrientationRange.marker.setMap(null);
            otherPlayersOrientationRange.delete(messageAttributes[MessageFields.messageId]);
        }
    }

    function processDebugLocationOrientationMessage(messageAttributes) {
        if (!scriptLoaded)
            return;

        console.log("processing debug message");

        var icon;
        if (sameTeam(messageAttributes[MessageFields.messageId])) {
            if (messageAttributes[LocationChangedFields.health] > 0) {
                icon = sameTeamPlayerAliveIcon;
            } else {
                icon = sameTeamPlayerDeadIcon;
            }
        } else {
            if (messageAttributes[LocationChangedFields.health] > 0) {
                var carryingFlag = false;
                teams.forEach((tempTeam) => {
                    if (tempTeam.flag.badguyCarrying && tempTeam.flag.badguyCarrying === messageAttributes[MessageFields.messageId])
                        carryingFlag = true;
                });
                icon = carryingFlag ? otherTeamPlayerYourFlagIcon : otherTeamPlayerAliveIcon;
            } else {
                icon = otherTeamPlayerDeadIcon;
            }
        }

        if (otherPlayers.has(messageAttributes[MessageFields.messageId])) {
            let target = otherPlayers.get(messageAttributes[MessageFields.messageId]);
            target.location = { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }
            target.marker.setPosition(new google.maps.LatLng(messageAttributes[DebugLocationOrientationFields.latitude], messageAttributes[DebugLocationOrientationFields.longitude]));
            target.marker.setIcon({ url: icon, anchor: new google.maps.Point(16, 16) });

            var accuracyMarker = new google.maps.Circle({
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: 'blue',
                fillOpacity: 0.35,
                map: map,
                center: new google.maps.LatLng(messageAttributes[DebugLocationOrientationFields.latitude], messageAttributes[DebugLocationOrientationFields.longitude]),
                radius: Number(messageAttributes[DebugLocationOrientationFields.accuracy]),
            });

            let targetAccuracy = otherPlayersAccuracy.get(messageAttributes[MessageFields.messageId]);
            targetAccuracy.location = { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }
            targetAccuracy.marker.setMap(null);
            targetAccuracy.marker = accuracyMarker;

            var bearingMarkerEndPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 1000, Number(messageAttributes[DebugLocationOrientationFields.orientation]));
            var bearingCoordinates = [
                { lat: Number(messageAttributes[DebugLocationOrientationFields.latitude]), lng: Number(messageAttributes[DebugLocationOrientationFields.longitude]) },
                bearingMarkerEndPoint,
            ];

            var orientationMarker = new google.maps.Polyline({
                path: bearingCoordinates,
                geodesic: true,
                strokeColor: 'green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map,
            });

            let targetOrientation = otherPlayersOrientation.get(messageAttributes[MessageFields.messageId]);
            targetOrientation.location = { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }
            targetOrientation.marker.setMap(null);
            targetOrientation.marker = orientationMarker;

            var bearingMarkerEndPoint2 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 1000, Number(messageAttributes[DebugLocationOrientationFields.orientation]) - orientationRange);
            var bearingMarkerEndPoint3 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 1000, Number(messageAttributes[DebugLocationOrientationFields.orientation]) + orientationRange);
            var bearingCoordinates2 = [
                bearingMarkerEndPoint2,
                { lat: Number(messageAttributes[DebugLocationOrientationFields.latitude]), lng: Number(messageAttributes[DebugLocationOrientationFields.longitude]) },
                bearingMarkerEndPoint3
            ];

            var orientationRangeMarker = new google.maps.Polyline({
                path: bearingCoordinates2,
                geodesic: true,
                strokeColor: 'orange',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map,
            });

            let targetOrientationRange = otherPlayersOrientationRange.get(messageAttributes[MessageFields.messageId]);
            targetOrientationRange.location = { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }
            targetOrientationRange.marker.setMap(null);
            targetOrientationRange.marker = orientationRangeMarker;


        } else {
            console.log("new player " + messageAttributes[MessageFields.messageId]);

            var targetMarker = new google.maps.Marker({
                position: new google.maps.LatLng(messageAttributes[DebugLocationOrientationFields.latitude], messageAttributes[DebugLocationOrientationFields.longitude]),
                map,
                title: messageAttributes[MessageFields.messageId],
                icon: { url: icon, anchor: new google.maps.Point(16, 16) }
            });

            if (!map) {
                queuedMarkers.push(targetMarker);
            }

            //TODO: infowindow1 - map may be null here
            google.maps.event.addListener(targetMarker, 'click', function () {
                var content = '<div>ID: ' + target.id + '<br>';
                content += 'Last Update: ' + messageAttributes[DebugLocationOrientationFields.timestamp] + '<br>';
                content += 'Latitude: ' + messageAttributes[DebugLocationOrientationFields.latitude] + '<br>';
                content += 'Longitude: ' + messageAttributes[DebugLocationOrientationFields.longitude] + '<br>';
                content += 'Accuracy: ' + messageAttributes[DebugLocationOrientationFields.accuracy] + '<br>';
                content += 'Orientation: ' + messageAttributes[DebugLocationOrientationFields.orientation] + '<br>';
                content += '</div>';
                const infoWindow1 = new google.maps.InfoWindow({
                    content: content
                });
                infoWindow1.open(map, targetMarker);
            });


            let target = new Target(messageAttributes[MessageFields.messageId], { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }, targetMarker, true);

            setOtherPlayers(map => new Map(map.set(messageAttributes[MessageFields.messageId], target)));

            var accuracyMarker = new google.maps.Circle({
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: 'blue',
                fillOpacity: 0.35,
                map: map,
                center: new google.maps.LatLng(messageAttributes[DebugLocationOrientationFields.latitude], messageAttributes[DebugLocationOrientationFields.longitude]),
                radius: Number(messageAttributes[DebugLocationOrientationFields.accuracy]),
            });
            if (!map) {
                queuedMarkers.push(accuracyMarker);
            }

            let targetAccuracy = new Target(messageAttributes[MessageFields.messageId], { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }, accuracyMarker, true);

            setOtherPlayersAccuracy(map => new Map(map.set(messageAttributes[MessageFields.messageId], targetAccuracy)));

            var bearingMarkerEndPoint = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 50, Number(messageAttributes[DebugLocationOrientationFields.orientation]));
            var bearingCoordinates = [
                { lat: Number(messageAttributes[DebugLocationOrientationFields.latitude]), lng: Number(messageAttributes[DebugLocationOrientationFields.longitude]) },
                bearingMarkerEndPoint,
            ];

            var orientationMarker = new google.maps.Polyline({
                path: bearingCoordinates,
                geodesic: true,
                strokeColor: 'green',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map,
            });

            if (!map) {
                queuedMarkers.push(orientationMarker);
            }

            let targetOrientation = new Target(messageAttributes[MessageFields.messageId], { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }, orientationMarker, true);

            setOtherPlayersOrientation(map => new Map(map.set(messageAttributes[MessageFields.messageId], targetOrientation)));

            var bearingMarkerEndPoint2 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 50, Number(messageAttributes[DebugLocationOrientationFields.orientation]) - orientationRange);
            var bearingMarkerEndPoint3 = google.maps.geometry.spherical.computeOffset(new google.maps.LatLng(Number(messageAttributes[DebugLocationOrientationFields.latitude]), Number(messageAttributes[DebugLocationOrientationFields.longitude])), 50, Number(messageAttributes[DebugLocationOrientationFields.orientation]) + orientationRange);
            var bearingCoordinates2 = [
                bearingMarkerEndPoint2,
                { lat: Number(messageAttributes[DebugLocationOrientationFields.latitude]), lng: Number(messageAttributes[DebugLocationOrientationFields.longitude]) },
                bearingMarkerEndPoint3
            ];

            var orientationRangeMarker = new google.maps.Polyline({
                path: bearingCoordinates2,
                geodesic: true,
                strokeColor: 'orange',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                map: map,
            });

            if (!map) {
                queuedMarkers.push(orientationRangeMarker);
            }

            let targetOrientationRange = new Target(messageAttributes[MessageFields.messageId], { latitude: Number(messageAttributes[DebugLocationOrientationFields.latitude]), longitude: Number(messageAttributes[DebugLocationOrientationFields.longitude]) }, orientationRangeMarker, true);

            setOtherPlayersOrientationRange(map => new Map(map.set(messageAttributes[MessageFields.messageId], targetOrientationRange)));


        }
    }

    function processServerUpdateTeamsMessage(messageAttributes) {
        console.log("processing update message");
        var tempTeams = JSON.parse(messageAttributes[2]);
        setTeams(tempTeams);
        //setTeams(teams);
        tempTeams.forEach((tempTeam) => {
            //setTeams(teams.concat(tempTeam));
            console.log(teams.length + " teams " + tempTeam.id + " " + tempTeam.name + " " + tempTeam.players.length);
            tempTeam.players.forEach((player) => {
                console.log(player.id + " " + player.userName);
            });
        });
    }

    function sameTeam(id) {
        if (!teams)
            return false;
        for (const otherPlayer of teams[0].players) {
            if (otherPlayer.id === id) {
                return true;
            }
        }
        return false;
    }

    function startGame() {
        console.log("starting game");
		(webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + calculateDropPointsId + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
        (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.startGameMessageType);
    }

    function resetServer() {
        var result = confirm("Are you sure you want to start a new game for everyone?");
        if (result)
            (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.resetServerMessageType);
    }

    function submitSettings() {

        var tempOrientationRange = Number(document.getElementById('orientationRange').value);
        var tempPickupRange = Number(document.getElementById('pickupRange').value);
        var tempM4Range = Number(document.getElementById('m4Range').value);
        var tempPistolRange = Number(document.getElementById('pistolRange').value);
        var tempGrenadeLauncherRange = Number(document.getElementById('glRange').value);
        setOrientationRange(tempOrientationRange);
        setPickupRange(tempPickupRange);
        setM4Range(tempM4Range);
        setPistolRange(tempPistolRange);
        setGrenadeLauncherRange(tempGrenadeLauncherRange);

        (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.gameSettingsMessageType + " " + myID + " " + tempOrientationRange + " " + tempPickupRange + " " + tempPistolRange + " " + tempM4Range + " " + tempGrenadeLauncherRange);

        console.log(tempOrientationRange + " " + tempPickupRange + " " + tempPistolRange + " " + tempM4Range + " " + tempGrenadeLauncherRange);
    }

    function mapClick(lat, lng) {
        var temp = document.getElementById('placeDropPoints').disabled && document.getElementById('placeDropPoints').style.visibility === 'visible';

        console.log("map clicked " + temp + " " + (document.getElementById('placeDropPoints').disabled) + " " + (document.getElementById('placeDropPoints').style.visibility === 'visible'));

        if (document.getElementById('placeDropPoints').disabled && document.getElementById('placeDropPoints').style.visibility === 'visible') {
            console.log("clicked drop point " + lat + " " + lng + " " + dropPoints.length + " " + weaponDropPoints.length);
            console.log(dropPoints);
            //document.getElementById('placeDropPoints').style.visibility = 'hidden';
            placeDropPoint(lat, lng);
        }
    }

    function placeDropPointClicked() {
        console.log("place drop point clicked");
        document.getElementById('placeDropPoints').disabled = true;
        document.getElementById('placeDropPointsButton').disabled = true;
    };

    function placeDropPoint(lat, lng) {
        if(weaponDropPoints.length===0){
            alert("Something failed, reload page");
            return;
        }
        dropPoints.push({ lat, lng });
        //setDropPoints(dropPoints.concat({lat, lng}));
        if (dropPoints.length === 5) {
            console.log(weaponDropPoints);
            console.log(supplyDropPoints);

            weaponDropPoints[0].location = dropPoints[0];
            weaponDropPoints[1].location = dropPoints[1];
            supplyDropPoints[0].location = dropPoints[2];
            supplyDropPoints[1].location = dropPoints[3];
            supplyDropPoints[2].location = dropPoints[4];

            (webSocket.readyState === WebSocket.OPEN) && webSocket.send(MessageTypes.dropPointMessageType + " " + "0" + " " + calculateDropPointsId + " " + JSON.stringify(weaponDropPoints) + " " + JSON.stringify(supplyDropPoints));
            document.getElementById('placeDropPoints').style.visibility = 'hidden';
            document.getElementById('placeDropPointsButton').style.visibility = 'hidden';

        }
    }

    function setMyMap(map) {
        map.addListener('click', (event) => {
            mapClick(event.latLng.toJSON().lat, event.latLng.toJSON().lng);

        });
        map.setCenter(new google.maps.LatLng(36.85, -76.46));
        setMap(map);
    }

	return (
	  <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
		
		<div>
		<h1 style={headingStyle}>Game Results</h1>       
		</div>
		
		<div>
		<p style={paragraphStyle}>Your team either won...or could use some more practice!! Either way, click below to play again! Results from each game are coming soon!</p>
		</div>
		
		<div id="placeDropPoints" style={{ visibility: 'visible' }} />

		  <button id="submit" className="button" onClick={resetServer}>
			Play New Game
		  </button>
	
		
		{/* 
		<div id="placeDropPoints" style={{ visibility: 'visible' }} />
		 
		  <button id="start" className="button" onClick={startGame}>
			Start Game For Everyone
		  </button> 
		*/}
		
	  
	  </div>
	);

}

export default Admin;