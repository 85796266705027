import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import Admin from './pages/Admin';
import PlaceStuff from './pages/PlaceStuff';
import reportWebVitals from './reportWebVitals';
import Location from './components/Location';
import ScreenD from './ScreenD';
import ScreenE from './ScreenE';
import ScreenF from './ScreenF';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="admin" element={<Admin />} />
        <Route path="place" element={<PlaceStuff />} />
		<Route path="location" element={<Location />} />
		<Route path="ScreenD" element={<ScreenD />} />
		<Route path="ScreenE" element={<ScreenE />} />
		
      </Routes>
    </BrowserRouter>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
