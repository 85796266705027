import Weapon from './Weapon';

export default class FirearmWeapon extends Weapon{
    constructor(name, soundSource, damage, ammoCount, ammoName, roundsPerShot, bearingMarkerColor) {
        super(name, soundSource, damage);
        this.ammoCount = ammoCount;
        this.ammoName = ammoName;
        this.roundsPerShot = roundsPerShot;
        this.bearingMarkerColor = bearingMarkerColor;
    }
}