import React from "react";
import PropTypes from "prop-types";

const Orientation = ({ orientation, error }) => {
  return (
    <div>
      {orientation ? (
        <code>
          Orientation: {Math.abs(orientation - 360)}
        </code>
      ) : (
        <p>Loading...</p>
      )}
      {error && <p className="errorMessage">Location Error: {error}</p>}
    </div>
  );
};

Orientation.propTypes = {
  orientation: Number,
  error: PropTypes.string,
};

export default Orientation;