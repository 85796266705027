// Modal.js
import React, { useState, useEffect } from 'react';
import './Modal1.css';

const Modal = ({ text, onClose }) => {
  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className={`modal ${showModal ? 'show' : ''}`}>
      <div className="modal-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default Modal;
