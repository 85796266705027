import React from "react";
import PropTypes from "prop-types";

const Health = ({ health, error }) => {
    return (
        <div>
            {
                <div className='health-container'>
                    <div/>
                        <div className={`grid-item box ${health>0 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>1 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>2 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>3 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>4 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>5 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>6 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>7 ? 'green' : 'red'}`} />
                        <div className={`grid-item box ${health>8 ? 'green' : 'red'}`} />
                    
                </div>
            }
            {error && <p className="errorMessage">Health Error: {error}</p>}
        </div>
    );
};

Health.propTypes = {
    health: PropTypes.number,
    error: PropTypes.string,
};

export default Health;