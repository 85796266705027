// ScreenE.js
import React from 'react';
import background from "./images/space_land.png";
import { Link } from 'react-router-dom';
import './App.css';

function ScreenE () {
  // Logic for Screen E
  
  
     // Style for all displayed text
  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };
  
  // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  // Style for paragraphs
  const paragraphStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '1rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
		<h1 style={headingStyle}>Screen E</h1>       
    
	<div>
		<Link to="/ScreenF" className="button">Go to Screen F!</Link>
	</div>
	</div>
  );
};

export default ScreenE;
