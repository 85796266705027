// ScreenC-b.js
import React, { useState } from 'react';
import background from "./images/space_abstract_1.png";


const ScreenCb = ({ onScreenChange }) => {
  // State and logic for tasks on Screen C-b

  const handleCompleteTasksCb = () => {
    // Logic for completing tasks on Screen C-b
    // Assuming successful completion, navigate to Screen D with view only rights
    onScreenChange('D');
  };
  console.log("Rendering Screen Cb");
  
   // Style for all displayed text
  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };
  
  // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  // Style for paragraphs
  const paragraphStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '1rem',  // Adjust font size as needed
    // Add other styles as needed
  };

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
	<h1 style={headingStyle}>Screen Cb</h1>
      {/* Render Screen C-b UI with tasks */}
      <button className='button-select' onClick={handleCompleteTasksCb}>Place Your Team Flag</button>
    </div>
  );
};

export default ScreenCb;
