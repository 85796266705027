// ScreenC-a.js
import React, { useState } from 'react';
import background from "./images/space_abstract_1.png";


const ScreenCa = ({ onScreenChange }) => {
  // State and logic for tasks on Screen C-a

  const handleCompleteTasksCa = () => {
	  console.log("Tasks completed on Screen Ca");
    // Logic for completing tasks on Screen C-a
    // Assuming successful completion, navigate to Screen D with edit rights
    onScreenChange('D');
  };
  console.log("Rendering Screen Ca");
  
  // Style for all displayed text
  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };
  
  // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };
  
  // Style for paragraphs
  const paragraphStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '1rem',  // Adjust font size as needed
    // Add other styles as needed
  };

  return (
    <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
	<h1 style={headingStyle}>Screen Ca</h1>
      {/* Render Screen C-a UI with tasks */}
      <button className='button-select' onClick={handleCompleteTasksCa}>Place Your Team Flag</button>
    </div>
  );
};

export default ScreenCa;
