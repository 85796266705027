import React, { useState, useEffect } from 'react';

const WebSocketComponent = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const url = 'wss://192.168.0.80:8765';
	const socket = new WebSocket(url);
	
	socket.onopen = () => {
      console.log('WebSocket connection opened');
      socket.send('Hello from client');
    };
	
	socket.onmessage = (event) => {
      console.log('The GPS is transmitting, well done!!:', event.data);
    };
	
	socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };
	
    socket.onclose = () => {
      console.log('WebSocket connection closed');
      // Optionally handle reconnection logic here
    };

    return () => {
      socket.close();
    };
  }, []);

  return (
    <div>
      <p>Latitude and Longitude:</p>

    </div>
  );
};

export default WebSocketComponent;

