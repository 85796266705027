// ScreenA.js
import React, { useState } from 'react';
import ScreenB from './ScreenB';  // Import ScreenB or adjust the import path
import background from "./images/deep_space_copy.png";
import PlaceStuff from './pages/PlaceStuff.js';
import Modal from './Modal1';

const ScreenA = ({ onScreenChange }) => {
  const [name, setName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [role, setRole] = useState('');
  const [showModal, setShowModal] = useState(true);

  const closeModal = () => {
    setShowModal(false);
  };
  
    // Style for h1 element
  const headingStyle = {
    color: 'white',  // Text color is set to white
    fontSize: '2rem',  // Adjust font size as needed
    // Add other styles as needed
  };

const handleStartSession = () => {
    // Check if the role is not the default (empty string)
    if (role !== '') {
      if (role === 'gameLeader') {
         console.log("Navigating to Place Items");
		 onScreenChange('placeStuff');
      } else {
        console.log("Navigating to Screen D");
		onScreenChange('D');
      }
    } else {
      // Show an error message or handle the case where the user has not selected a role
      alert('Please select a role!');
      
    }
  };


  // Predefined image options
  const imageOptions = [
    './images/assassin_icon_mvp.png',
    './images/robotic_assassin_icon.png',
    './images/steampunk_female_icon.png',
  ];

  const optionStyle = {
    color: 'black',  // Change color as needed
	'background-color': '#f9f9f9',
	'min-width': '160px',
	'box-shadow': '0px 8px 16px 0px rgba(0,0,0,0.2)',
	//'z-index': '1',
    // Add other styles as needed
  };

 // Style for all displayed text
  const textStyle = {
    color: 'white',  // Text color is set to white
    // Add other styles as needed
  };

  return (
	  <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: '100vh' }}>
      {/*
	  <label style={textStyle}>
        Choose Picture:
        <select value={selectedImage} onChange={(e) => setSelectedImage(e.target.value)}>
          <option value="" disabled>Select an image</option>
          {imageOptions.map((image, index) => (
            <option key={index} style={optionStyle} value={image}>{image}</option>
          ))}
        </select>
      </label> 
	  */}
	  
      <label style={textStyle}>
        Select Role:
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="">Select your role</option>
          <option style={optionStyle} value="gameLeader">Organizer</option>
          <option style={optionStyle} value="player">Player</option>
        </select>
      </label>
      <button className='button-select' onClick={handleStartSession} style={textStyle}>Submit</button>
	  
	  <div>
		<h1 style={headingStyle}>Game Organizer</h1>       
	  </div>		

	  <div>
	  <label style={textStyle}>
	  Only one player chooses organizer. If that's you, wait until all other players have selected a team, placed their flag, and hit ready.
	  </label>
	  </div>
	  
	  <br />
	  <div>
		<h1 style={headingStyle}>Game Player</h1>       
	  </div>
	  
	  <div>
	  <label style={textStyle}>
	  All other players choose player and proceed to the next screen. If you are the first one on your team, you can place the flag. Go ahead and place it. 
	  </label>
	  </div>
	  
	  <div>
	  {showModal && <Modal text="by TMTQuest" onClose={closeModal} />}
	  </div>
    </div>
  );
};

export default ScreenA;